import { Grid, TextField } from "@mui/material";
import React, { useContext } from "react";

import { CustomContext } from "../../context/CustomContext";

function InputNumber({ section, index, id, value }) {
  const {
    customBtns,
    setCustomBtnData,
    customGuidance,
    setCustomGuidanceData,
    setCustomReadyScreen,
    customReadyScreen,
    customRetryScreen,
    setCustomRetryScreen,
    customResultScreen,
    setCustomResultScreen,
    customOvalScreen,
    setCustomOvalScreen,
    customFeedback,
    setCustomFeedback,
    customFrame,
    setCustomFrame,
    customOverlay,
    setCustomOverlay,
  } = useContext(CustomContext);

  const handleValue = (value) => {
    if (section === "btns") {
      setCustomBtnData(
        customBtns.map((item) =>
          item.id === id ? { ...item, value } : { ...item },
        ),
      );
    }
    if (section === "guidance") {
      setCustomGuidanceData(
        customGuidance.map((item) =>
          item.id === id ? { ...item, value } : { ...item },
        ),
      );
    }
    if (section === "ready") {
      setCustomReadyScreen(
        customReadyScreen.map((item) =>
          item.id === id ? { ...item, value } : { ...item },
        ),
      );
    }
    if (section === "retry") {
      setCustomRetryScreen(
        customRetryScreen.map((item) =>
          item.id === id ? { ...item, value } : { ...item },
        ),
      );
    }
    if (section === "result") {
      setCustomResultScreen(
        customResultScreen.map((item) =>
          item.id === id ? { ...item, value } : { ...item },
        ),
      );
    }
    if (section === "oval") {
      setCustomOvalScreen(
        customOvalScreen.map((item) =>
          item.id === id ? { ...item, value } : { ...item },
        ),
      );
    }
    if (section === "frame") {
      setCustomFrame(
        customFrame.map((item) =>
          item.id === id ? { ...item, value } : { ...item },
        ),
      );
    }

    if (section === "overlay") {
      setCustomOverlay(
        customOverlay.map((item) =>
          item.id === id ? { ...item, value } : { ...item },
        ),
      );
    }
    if (section === "feedback") {
      setCustomFeedback(
        customFeedback.map((item) =>
          item.id === id ? { ...item, value } : { ...item },
        ),
      );
    }
  };

  return (
    <div>
      <Grid item xs="12" md="12">
        <TextField
          id="filled-number"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={value}
          variant="standard"
          onChange={(e) => handleValue(e.target.value)}
        />
      </Grid>
    </div>
  );
}

export default InputNumber;
