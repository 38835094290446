import { Download } from "@mui/icons-material";
import { Button, Grid, Tooltip } from "@mui/material";
import React, { useContext } from "react";

import { CustomContext } from "../../context/CustomContext";

import FontPicker from "font-picker-react";

function FontPickerC({ section, id, value }) {
  const {
    customBtns,
    setCustomBtnData,
    customGuidance,
    setCustomGuidanceData,
    setCustomReadyScreen,
    customReadyScreen,
    customRetryScreen,
    setCustomRetryScreen,
    customResultScreen,
    setCustomResultScreen,
    customOvalScreen,
    setCustomOvalScreen,
    customFeedback,
    setCustomFeedback,
    customFrame,
    setCustomFrame,
    customOverlay,
    setCustomOverlay,
  } = useContext(CustomContext);

  const [fontFamily, setFontFamily] = React.useState(value);
  const [fontId, setFontId] = React.useState(value.toLowerCase());
  const [fontUrl, setFontUrl] = React.useState("");

  const handleValue = (font) => {
    const hex = font.family;
    setFontFamily(hex);
    setFontId(font.id);
    setFontUrl(font.menu.replace(/^http:\/\//i, "https://"));
    if (section === "btns") {
      setCustomBtnData(
        customBtns.map((item) =>
          item.id === id ? { ...item, value: hex } : { ...item },
        ),
      );
    }
    if (section === "guidance") {
      setCustomGuidanceData(
        customGuidance.map((item) =>
          item.id === id ? { ...item, value: hex } : { ...item },
        ),
      );
    }
    if (section === "ready") {
      setCustomReadyScreen(
        customReadyScreen.map((item) =>
          item.id === id ? { ...item, value: hex } : { ...item },
        ),
      );
    }
    if (section === "retry") {
      setCustomRetryScreen(
        customRetryScreen.map((item) =>
          item.id === id ? { ...item, value: hex } : { ...item },
        ),
      );
    }
    if (section === "result") {
      setCustomResultScreen(
        customResultScreen.map((item) =>
          item.id === id ? { ...item, value: hex } : { ...item },
        ),
      );
    }
    if (section === "oval") {
      setCustomOvalScreen(
        customOvalScreen.map((item) =>
          item.id === id ? { ...item, value: hex } : { ...item },
        ),
      );
    }
    if (section === "frame") {
      setCustomFrame(
        customFrame.map((item) =>
          item.id === id ? { ...item, value: hex } : { ...item },
        ),
      );
    }
    if (section === "overlay") {
      setCustomOverlay(
        customOverlay.map((item) =>
          item.id === id ? { ...item, value: hex } : { ...item },
        ),
      );
    }
    if (section === "feedback") {
      setCustomFeedback(
        customFeedback.map((item) =>
          item.id === id ? { ...item, value: hex } : { ...item },
        ),
      );
    }
  };

  return (
    <div>
      <Grid container direction="row">
        <Grid item xs={8} md={8}>
          <FontPicker
            apiKey="AIzaSyCIWtco2jKJLXs9_K1CbYYzd5SpbdKno5A"
            activeFontFamily={fontFamily}
            onChange={(nextFont) => handleValue(nextFont)}
            pickerId={id}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <Tooltip title={`Baixar arquivo da fonte ${fontFamily}.`}>
            <a
              style={{
                cursor: "pointer",
              }}
              href={fontUrl}
              download={fontId + ".ttf"}
            >
              <Button
                style={{
                  marginTop: "9px",
                }}
                className={`btn-default`}
                variant="outlined"
                fullWidth
                startIcon={<Download />}
              >
                .tff
              </Button>
            </a>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
}

export default FontPickerC;
