import "./App.css";
import { Grid } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { CustomProvider } from "./context/CustomContext";
import Navigation from "./components/Navigation";
import { IframeCustomizationProvider } from "./context/IframeCustomizationContext";
import Logo from "./components/Logo";
import Menu from "./components/Menu";

const customRoutes = createBrowserRouter([
  { path: "/", element: <Navigation homolog={false} /> },
  { path: "/homolog", element: <Navigation homolog={true} /> },
]);
function App() {
  return (
    <CustomProvider>
      <div className="App">
        <Grid container className="App-menu" xs={12} md={12}>
          <Grid className="logo-area" xs={6} md={6}>
            <Logo />
          </Grid>
          <Grid className="menu-area" xs={6} md={6}>
            <Menu />
          </Grid>
          <IframeCustomizationProvider>
            <RouterProvider router={customRoutes} />
            <ToastContainer />
          </IframeCustomizationProvider>
        </Grid>
      </div>
    </CustomProvider>
  );
}

export default App;
