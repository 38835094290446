import { Button, Tooltip } from "@mui/material";
import { CustomContext } from "../../context/CustomContext";
import { useContext } from "react";
import { Android, Apple } from "@mui/icons-material";
import { SiFlutter } from "react-icons/si";
import { FaReact } from "react-icons/fa";

export default function SwitchPlatform() {
  const { os, setOs } = useContext(CustomContext);
  return (
    <>
      <Tooltip title="Android">
        <Button
          className={`btn-default-platform ${
            os === "android" && "btn-default-platform-selected"
          }`}
          variant="outlined"
          onClick={() => setOs("android")}
        >
          <Android />
        </Button>
      </Tooltip>
      <Tooltip title="iOS">
        <Button
          className={`btn-default-platform ${
            os === "ios" && "btn-default-platform-selected"
          }`}
          variant="outlined"
          onClick={() => setOs("ios")}
        >
          <Apple />
        </Button>
      </Tooltip>
      <Tooltip title="RN">
        <Button
          className={`btn-default-platform ${
            os === "reactnative" && "btn-default-platform-selected"
          }`}
          variant="outlined"
          onClick={() => setOs("reactnative")}
        >
          <FaReact size="22" />
        </Button>
      </Tooltip>
      <Tooltip title="Flutter">
        <Button
          className={`btn-default-platform ${
            os === "flutter" && "btn-default-platform-selected"
          }`}
          variant="outlined"
          onClick={() => setOs("flutter")}
        >
          <SiFlutter size="22" />
        </Button>
      </Tooltip>
    </>
  );
}
