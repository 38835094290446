import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { CustomContext } from "../../context/CustomContext";

function InputShadow({ section, id }) {
  const [inputState, setInputState] = useState({
    color: "#000000",
    opacity: 0.5,
    radius: 5,
    offset: {
      width: 0,
      height: 0,
    },
    insets: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
  });
  const {
    customFeedback,
    setCustomFeedback,
    customFrame,
    setCustomFrame,
    isIsolated,
  } = useContext(CustomContext);

  const handleValue = () => {
    switch (section) {
      case "feedback":
        setCustomFeedback(
          customFeedback.map((item) =>
            item.id === id ? { ...item, value: inputState } : item,
          ),
        );
        break;
      case "frame":
        setCustomFrame(
          customFrame.map((item) =>
            item.id === id ? { ...item, value: inputState } : item,
          ),
        );
        break;
      default:
        console.log("Unknown section", section);
    }
  };

  useEffect(() => {
    handleValue();
  }, [inputState]);

  const handleInputChange = (propertyName, newValue) => {
    const value = propertyName === "color" ? newValue : Number(newValue);
    setInputState((prevValue) => ({
      ...prevValue,
      [propertyName]: value,
    }));
  };

  const handleOffsetChange = (property, newValue) => {
    const value = Number(newValue);
    setInputState((prevValue) => ({
      ...prevValue,
      offset: {
        ...prevValue.offset,
        [property]: value,
      },
    }));
  };

  const handleInsetsChange = (property, newValue) => {
    const value = Number(newValue);
    setInputState((prevValue) => ({
      ...prevValue,
      insets: {
        ...prevValue.insets,
        [property]: value,
      },
    }));
  };

  const acordeonStyle = { width: "100%", backgroundColor: "#163d57" };

  return (
    <div>
      {isIsolated && (
        <Grid container>
          <Accordion style={acordeonStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Cor, opacidade e radius</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    id="color-input"
                    label="Color"
                    variant="standard"
                    placeholder={"#000000"}
                    inputState={inputState.color}
                    onChange={(event) =>
                      handleInputChange("color", event.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="opacity-input"
                    label="Opacity"
                    variant="standard"
                    type={"number"}
                    placeholder={"0"}
                    inputState={inputState.opacity}
                    onChange={(event) =>
                      handleInputChange("opacity", event.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="radius-input"
                    label="Radius"
                    type={"number"}
                    placeholder={"0"}
                    variant="standard"
                    inputState={inputState.radius}
                    onChange={(event) =>
                      handleInputChange("radius", event.target.value)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion style={acordeonStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Offset</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    id="offset-width-input"
                    label="Offset Width"
                    variant="standard"
                    type={"number"}
                    placeholder={"0"}
                    value={inputState.offset.width}
                    onChange={(event) =>
                      handleOffsetChange("width", event.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="offset-height-input"
                    label="Offset Height"
                    variant="standard"
                    type={"number"}
                    placeholder={"0"}
                    inputState={inputState.offset.height}
                    onChange={(event) =>
                      handleOffsetChange("height", event.target.value)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion style={acordeonStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Insets</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    id="insets-top-input"
                    label="Insets Top"
                    variant="standard"
                    type={"number"}
                    placeholder={"0"}
                    inputState={inputState.insets.top}
                    onChange={(event) =>
                      handleInsetsChange("top", event.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="insets-left-input"
                    label="Insets Left"
                    variant="standard"
                    type={"number"}
                    placeholder={"0"}
                    inputState={inputState.insets.left}
                    onChange={(event) =>
                      handleInsetsChange("left", event.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="insets-bottom-input"
                    label="Insets Bottom"
                    variant="standard"
                    type={"number"}
                    placeholder={"0"}
                    inputState={inputState.insets.bottom}
                    onChange={(event) =>
                      handleInsetsChange("bottom", event.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="insets-right-input"
                    label="Insets Right"
                    variant="standard"
                    type={"number"}
                    placeholder={"0"}
                    inputState={inputState.insets.right}
                    onChange={(event) =>
                      handleInsetsChange("right", event.target.value)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </div>
  );
}

export default InputShadow;
