import React, { createContext, useEffect, useState } from "react";

import inputs from "../components/ConfigList/inputs";
import btnsJson from "../components/ConfigList/btns.json";
import guidanceJson from "../components/ConfigList/guidance.json";
import readyScreen from "../components/ConfigList/os/android/readyscreen.json";
import retryScreen from "../components/ConfigList/os/android/retryscreen.json";
import resultScreen from "../components/ConfigList/os/android/resultscreen.json";
import ovalJson from "../components/ConfigList/os/android/oval.json";
import frameJson from "../components/ConfigList/os/android/frame.json";
import overlayJson from "../components/ConfigList/os/android/overlay.json";
import feedbackJson from "../components/ConfigList/os/android/feedback.json";

export const CustomContext = createContext(null);
export const CustomProvider = ({ children }) => {
  const [custom, setCustom] = useState(inputs);

  const queryParameters = new URLSearchParams(window.location.search);
  const osP = queryParameters.get("osP");
  // navigation
  const [tab, setTab] = useState("custom");
  const [screen, setScreen] = useState(0);
  const [os, setOs] = useState("android");
  const [classListener, setClassListener] = useState("");
  const [language, setLanguage] = useState("kotlin");
  const [customTab, setCustomTab] = useState("theme");
  // Isolated iOS theme
  const [isIsolated, setIsIsolated] = useState(false);

  useEffect(() => {
    if (osP) {
      setOs(osP);
    } else {
      setOs("android");
    }
  }, [osP]);

  // TODO: Remover esse estado, não está sendo usado
  const [texts, setTexts] = useState({
    // Ready Screen
    ready: {
      READY_HEADER_1: "Prepare-se para seu",
      READY_HEADER_2: "reconhecimento facial.",
      READY_MESSAGE_1: "Posicione o seu rosto na moldura, aproxime-se",
      READY_MESSAGE_2: "e toque em começar.",
      READY_BUTTON: "Começar",
    },
    // Retry Screen
    retry: {
      RETRY_HEADER: "Vamos tentar novamente?",
      RETRY_SUBHEADER: "Siga o exemplo de foto ideal abaixo:",
      RETRY_MESSAGE_SMILE: "Expressão Neutra, Sem Sorrir",
      RETRY_MESSAGE_LIGHTING: "Evite reflexos e iluminação extrema.",
      RETRY_MESSAGE_CONTRAST: "Limpe Sua Câmera",
      RETRY_YOUR_PICTURE: "Sua foto",
      RETRY_IDEAL_PICTURE: "Foto ideal",
      RETRY_BUTTON: "Tentar novamente",
    },
    // Result Screen
    result: {
      RESULT_UPLOAD_MESSAGE: "Enviando...",
      RESULT_SUCCESS_MESSAGE: "Sucesso",
    },
    // Feedback Screen
    feedback: {
      FEEDBACK_CENTER_FACE: "Centralize Seu Rosto",
      FEEDBACK_FACE_NOT_FOUND: "Enquadre o Seu Rosto",
      FEEDBACK_FACE_NOT_LOOKING_STRAIGHT_AHEAD: "Olhe Para Frente",
      FEEDBACK_FACE_NOT_UPRIGHT: "Mantenha a Cabeça Reta",
      FEEDBACK_HOLD_STEADY: "Segure Firme",
      FEEDBACK_MOVE_PHONE_AWAY: "Afaste-se",
      FEEDBACK_MOVE_PHONE_CLOSER: "Aproxime-se",
      FEEDBACK_MOVE_PHONE_TO_EYE_LEVEL: "Telefone ao Nível dos Olhos",
      FEEDBACK_USE_EVEN_LIGHTING: "Ilumine Seu Rosto Uniformemente",
      FEEDBACK_FRAME_YOUR_FACE: "Encaixe Seu Rosto no Espaço Oval",
      FEEDBACK_HOLD_STEADY_1: "Aguente Firme: 1",
      FEEDBACK_HOLD_STEADY_2: "Aguente Firme: 2",
      FEEDBACK_HOLD_STEADY_3: "Aguente Firme: 3",
      FEEDBACK_REMOVE_DARK_GLASSES: "Tire Seus Óculos de Sol",
      FEEDBACK_NEUTRAL_EXPRESSION: "Fique Neutro, Não Sorria",
      FEEDBACK_CONDITIONS_TOO_BRIGHT: "Ambiente Muito Iluminado",
      FEEDBACK_BRIGHTEN_YOUR_ENVIRONMENT: "Ambiente Muito Escuro",
    },
  });

  const [textsReady, setTextsReady] = useState({
    READY_HEADER_1: "Prepare-se para seu",
    READY_HEADER_2: "reconhecimento facial.",
    READY_MESSAGE_1: "Posicione o seu rosto na moldura, aproxime-se",
    READY_MESSAGE_2: "e toque em começar.",
    READY_BUTTON: "Começar",
  });

  const [textsRetry, setTextsRetry] = useState({
    RETRY_HEADER: "Vamos tentar novamente?",
    RETRY_SUBHEADER: "Siga o exemplo de foto ideal abaixo:",
    RETRY_MESSAGE_SMILE: "Expressão Neutra, Sem Sorrir",
    RETRY_MESSAGE_LIGHTING: "Evite reflexos e iluminação extrema.",
    RETRY_MESSAGE_CONTRAST: "Limpe Sua Câmera",
    RETRY_YOUR_PICTURE: "Sua foto",
    RETRY_IDEAL_PICTURE: "Foto ideal",
    RETRY_BUTTON: "Tentar novamente",
  });

  const [textsResult, setTextsResult] = useState({
    RESULT_UPLOAD_MESSAGE: "Enviando...",
    RESULT_SUCCESS_MESSAGE: "Sucesso",
  });

  const [textsFeedback, setTextsFeedback] = useState({
    FEEDBACK_CENTER_FACE: "Centralize Seu Rosto",
    FEEDBACK_FACE_NOT_FOUND: "Enquadre o Seu Rosto",
    FEEDBACK_FACE_NOT_LOOKING_STRAIGHT_AHEAD: "Olhe Para Frente",
    FEEDBACK_FACE_NOT_UPRIGHT: "Mantenha a Cabeça Reta",
    FEEDBACK_HOLD_STEADY: "Segure Firme",
    FEEDBACK_MOVE_PHONE_AWAY: "Afaste-se",
    FEEDBACK_MOVE_PHONE_CLOSER: "Aproxime-se",
    FEEDBACK_MOVE_PHONE_TO_EYE_LEVEL: "Telefone ao Nível dos Olhos",
    FEEDBACK_USE_EVEN_LIGHTING: "Ilumine Seu Rosto Uniformemente",
    FEEDBACK_FRAME_YOUR_FACE: "Encaixe Seu Rosto no Espaço Oval",
    FEEDBACK_HOLD_STEADY_1: "Aguente Firme: 1",
    FEEDBACK_HOLD_STEADY_2: "Aguente Firme: 2",
    FEEDBACK_HOLD_STEADY_3: "Aguente Firme: 3",
    FEEDBACK_REMOVE_DARK_GLASSES: "Tire Seus Óculos de Sol",
    FEEDBACK_NEUTRAL_EXPRESSION: "Fique Neutro, Não Sorria",
    FEEDBACK_CONDITIONS_TOO_BRIGHT: "Ambiente Muito Iluminado",
    FEEDBACK_BRIGHTEN_YOUR_ENVIRONMENT: "Ambiente Muito Escuro",
  });

  const [customGuidance, setCustomGuidance] = useState(guidanceJson);
  const [customBtns, setCustomBtns] = useState(btnsJson);
  const [customReadyScreen, setCustomReadyScreen] = useState(readyScreen);
  const [customRetryScreen, setCustomRetryScreen] = useState(retryScreen);
  const [customResultScreen, setCustomResultScreen] = useState(resultScreen);
  const [customOvalScreen, setCustomOvalScreen] = useState(ovalJson);
  const [customFrame, setCustomFrame] = useState(frameJson);
  const [customOverlay, setCustomOverlay] = useState(overlayJson);
  const [customFeedback, setCustomFeedback] = useState(feedbackJson);

  const setCustomData = (data) => {
    setCustom(data);
  };

  const setCustomBtnData = (data) => {
    setCustomBtns(data);
  };
  const setCustomGuidanceData = (data) => {
    setCustomGuidance(data);
  };

  const handleScreen = (index) => {
    setScreen(index);
  };

  return (
    <CustomContext.Provider
      value={{
        custom,
        setCustomData,
        setCustomBtns,
        customBtns,
        setCustomBtnData,
        customGuidance,
        setCustomGuidanceData,
        setCustomGuidance,
        customReadyScreen,
        setCustomReadyScreen,
        customRetryScreen,
        setCustomRetryScreen,
        customResultScreen,
        setCustomResultScreen,
        customOvalScreen,
        setCustomOvalScreen,
        customFeedback,
        setCustomFeedback,
        customFrame,
        setCustomFrame,
        customOverlay,
        setCustomOverlay,
        tab,
        setTab,
        os,
        setOs,
        language,
        setLanguage,
        screen,
        setScreen,
        handleScreen,
        classListener,
        setClassListener,
        texts,
        setTexts,
        customTab,
        setCustomTab,
        textsReady,
        textsRetry,
        textsResult,
        textsFeedback,
        setTextsReady,
        setTextsRetry,
        setTextsResult,
        setTextsFeedback,
        isIsolated,
        setIsIsolated,
      }}
    >
      {children}
    </CustomContext.Provider>
  );
};
