import { useContext } from "react";
import { CustomContext } from "../../context/CustomContext";
import GeneratedCodeAndroid from "./android";
import GeneratedCodeIos from "./ios";
import GeneratedCodeRN from "./reactnative";
import GeneratedCodeFlutter from "./flutter";
function GeneratedCode() {
  const { os } = useContext(CustomContext);

  return (
    <>
      {os === "android" && <GeneratedCodeAndroid />}
      {os === "ios" && <GeneratedCodeIos />}
      {os === "reactnative" && <GeneratedCodeRN />}
      {os === "flutter" && <GeneratedCodeFlutter />}
    </>
  );
}

export default GeneratedCode;
