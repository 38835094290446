import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { CustomContext } from "../../context/CustomContext";
import InputImage from "../InputImage";

function InputAnimation({ section, id }) {
  const [animationState, setAnimationState] = useState({
    blob: {
      blobColor: "#FFFFFF",
      checkmarkForegroundColor: "#FFFFFF",
      checkmarkBackgroundColor: "#FFFFFF",
    },
    spinner: {
      spinnerColor: "#FFFFFF",
      checkmarkForegroundColor: "#FFFFFF",
      checkmarkBackgroundColor: "#FFFFFF",
    },
    image: {
      image: "",
      rotationInterval: 3,
      checkmarkForegroundColor: "#FFFFFF",
      checkmarkBackgroundColor: "#FFFFFF",
    },
    custom: {
      createActivityIndicatorView: "function void",
      createSuccessAnimationView: "function void",
    },
  });

  const { customResultScreen, setCustomResultScreen, isIsolated } =
    useContext(CustomContext);

  const handleValue = () => {
    switch (section) {
      case "result":
        setCustomResultScreen(
          customResultScreen.map((item) =>
            item.id === id ? { ...item, value: animationState } : item,
          ),
        );
        break;
      default:
        console.log("Unknown section", section);
    }
  };

  useEffect(() => {
    handleValue();
  }, [animationState]);

  const handleInputChange = (animationType, propertyName, newValue) => {
    const value =
      propertyName === "rotationInterval" ? Number(newValue) : newValue;
    setAnimationState((prevValue) => ({
      ...prevValue,
      [animationType]: {
        ...prevValue[animationType],
        [propertyName]: value,
      },
    }));
  };

  const acordeonStyle = { width: "100%", backgroundColor: "#163d57" };

  return (
    <div>
      {isIsolated && (
        <Grid container>
          <Accordion style={acordeonStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Blob</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="color-input"
                    label="Blob Color"
                    variant="standard"
                    placeholder={"#000000"}
                    inputState={animationState.blob.blobColor}
                    onChange={(event) =>
                      handleInputChange("blob", "blobColor", event.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="color-input"
                    label="Checkmark Foreground Color"
                    variant="standard"
                    placeholder={"#000000"}
                    inputState={animationState.blob.checkmarkForegroundColor}
                    onChange={(event) =>
                      handleInputChange(
                        "blob",
                        "checkmarkForegroundColor",
                        event.target.value,
                      )
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="color-input"
                    label="Checkmark Background Color"
                    variant="standard"
                    placeholder={"#000000"}
                    inputState={animationState.blob.checkmarkBackgroundColor}
                    onChange={(event) =>
                      handleInputChange(
                        "blob",
                        "checkmarkBackgroundColor",
                        event.target.value,
                      )
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion style={acordeonStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Spinner</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="color-input"
                    label="Spinner Color"
                    variant="standard"
                    placeholder={"#000000"}
                    inputState={animationState.spinner.spinnerColor}
                    onChange={(event) =>
                      handleInputChange("spinner", "color", event.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="color-input"
                    label="Checkmark Foreground Color"
                    variant="standard"
                    placeholder={"#000000"}
                    inputState={animationState.spinner.checkmarkForegroundColor}
                    onChange={(event) =>
                      handleInputChange(
                        "spinner",
                        "checkmarkForegroundColor",
                        event.target.value,
                      )
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="color-input"
                    label="Checkmark Background Color"
                    variant="standard"
                    placeholder={"#000000"}
                    inputState={animationState.spinner.checkmarkBackgroundColor}
                    onChange={(event) =>
                      handleInputChange(
                        "spinner",
                        "checkmarkBackgroundColor",
                        event.target.value,
                      )
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion style={acordeonStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Image</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <InputImage
                    id={"resultScreenCustomizationAnimationStyle"}
                    section={"result"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="insets-left-input"
                    label="Rotation Interval"
                    variant="standard"
                    type={"number"}
                    placeholder={"0"}
                    inputState={animationState.image.rotationInterval}
                    onChange={(event) =>
                      handleInputChange(
                        "image",
                        "rotationInterval",
                        event.target.value,
                      )
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="color-input"
                    label="Checkmark Foreground Color"
                    variant="standard"
                    placeholder={"#000000"}
                    inputState={animationState.image.checkmarkForegroundColor}
                    onChange={(event) =>
                      handleInputChange(
                        "image",
                        "checkmarkForegroundColor",
                        event.target.value,
                      )
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="color-input"
                    label="Checkmark Background Color"
                    variant="standard"
                    placeholder={"#000000"}
                    inputState={animationState.image.checkmarkBackgroundColor}
                    onChange={(event) =>
                      handleInputChange(
                        "image",
                        "checkmarkBackgroundColor",
                        event.target.value,
                      )
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </div>
  );
}

export default InputAnimation;
