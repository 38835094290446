import { Grid } from "@mui/material";
import arrowBack from "../../assets/img/arrow.png";
import okCheck from "../../assets/img/ok.png";
import screenRetry from "../../assets/img/screenRetry.png";
import screenReady from "../../assets/img/screenReady.png";
import screenfeedbackOk from "../../assets/img/feedbackOk.png";
import "../../assets/css/Carousel.css";
import "../../assets/css/Screens.css";

import { useCallback, useContext, useEffect, useState } from "react";

import { CustomContext } from "../../context/CustomContext";
import { styled } from "styled-components";
import { Carousel } from "react-responsive-carousel";

function ScreenReady() {
  const {
    customBtns,
    customReadyScreen,
    customOvalScreen,
    customFeedback,
    customFrame,
    customOverlay,
    textsReady,
    textsFeedback,
  } = useContext(CustomContext);

  const names = [
    textsFeedback.FEEDBACK_CENTER_FACE,
    textsFeedback.FEEDBACK_FACE_NOT_FOUND,
    textsFeedback.FEEDBACK_FACE_NOT_LOOKING_STRAIGHT_AHEAD,
    textsFeedback.FEEDBACK_FACE_NOT_UPRIGHT,
    textsFeedback.FEEDBACK_HOLD_STEADY,
    textsFeedback.FEEDBACK_MOVE_PHONE_AWAY,
    textsFeedback.FEEDBACK_MOVE_PHONE_CLOSER,
    textsFeedback.FEEDBACK_MOVE_PHONE_TO_EYE_LEVEL,
    textsFeedback.FEEDBACK_USE_EVEN_LIGHTING,
    textsFeedback.FEEDBACK_FRAME_YOUR_FACE,
    textsFeedback.FEEDBACK_HOLD_STEADY_1,
    textsFeedback.FEEDBACK_HOLD_STEADY_2,
    textsFeedback.FEEDBACK_HOLD_STEADY_3,
    textsFeedback.FEEDBACK_REMOVE_DARK_GLASSES,
    textsFeedback.FEEDBACK_NEUTRAL_EXPRESSION,
    textsFeedback.FEEDBACK_CONDITIONS_TOO_BRIGHT,
    textsFeedback.FEEDBACK_BRIGHTEN_YOUR_ENVIRONMENT,
  ];

  const Button = styled.button`
    background: ${customBtns[2].value};
    color: ${customBtns[1].value};
    border: ${customBtns[7].value} solid ${customBtns[8].value}px;
    border-radius: ${customBtns[9].value}px;
    font-family: "${customBtns[0].value}" !important;
  `;

  const FedbackText = styled.div`
    color: ${customFeedback[2].value};
    font-size: 16px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: "${customFeedback[3].value}" !important;
  `;

  const ReadyHeader = styled.div`
    font-family: "${customReadyScreen[0].value}" !important;
    color: ${customReadyScreen[1].value};
    font-size: 20px;
    font-weight: 700;
  `;
  const ReadySubHeader = styled.div`
    font-family: "${customReadyScreen[2].value}" !important;
    color: ${customReadyScreen[3].value};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  `;

  const [feedbackTexy, setFeedbackText] = useState(
    textsFeedback.FEEDBACK_HOLD_STEADY_1,
  );

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * names.length);
    setFeedbackText(names[index]);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 5000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  return (
    <div
      className="scrren1"
      style={{
        background: customOverlay[0].value,
      }}
    >
      <div
        style={{
          background: customFrame[3].value,
          borderRadius: `${customFrame[1].value}px`,
          border: `${customFrame[2].value} solid ${customFrame[0].value}px`,
          paddingBottom: "10px",
          boxShadow:
            customFrame[4].value === 0
              ? "none"
              : `0px 0px ${customFrame[4].value}px #000000`,
        }}
      >
        <Grid container>
          <Grid className="arrowbox" item xs={6} md={6}>
            <img
              alt=""
              src={arrowBack}
              className="displayBlock"
              style={{
                width: "10px",
                display: "block !important",
                height: "auto",
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}></Grid>
        </Grid>
        <ReadyHeader>
          <mark
            style={{
              background: customReadyScreen[4].value,
              color: customReadyScreen[1].value,
              borderRadius: customReadyScreen[5].value + "px",
            }}
          >
            {textsReady.READY_HEADER_1}{" "}
          </mark>
        </ReadyHeader>
        <ReadyHeader>
          <mark
            style={{
              background: customReadyScreen[4].value,
              color: customReadyScreen[1].value,
              borderRadius: customReadyScreen[5].value + "px",
            }}
          >
            {textsReady.READY_HEADER_2}
          </mark>
        </ReadyHeader>
        <Grid
          container
          className="letsgo-text"
          alignItems="center"
          justifyContent="center"
          style={{
            marginBottom: "-50px",
            marginTop: "15px",
            position: "relative",
          }}
        >
          <div
            style={{
              width: "80%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              height: "45px",
              borderRadius: `${customFeedback[0].value}px`,
              background: `${customFeedback[1].value}`,
              color: "#FFF",
            }}
          >
            <FedbackText className="shine">{feedbackTexy}</FedbackText>
          </div>
        </Grid>
        <Grid container justifyContent="center">
          <div
            className="ovalFace"
            style={{
              borderColor: `${customOvalScreen[1].value}`,
              border: `${customOvalScreen[0].value}px solid ${customOvalScreen[1].value}`,
            }}
          ></div>
        </Grid>
        <Grid container justifyContent="center">
          <ReadySubHeader>
            {textsReady.READY_MESSAGE_1 + " "}
            {textsReady.READY_MESSAGE_2}
          </ReadySubHeader>
        </Grid>

        <Grid className="boxBtn" container justifyContent="center">
          <Button className="btn-primary">{textsReady.READY_BUTTON}</Button>
        </Grid>
      </div>
    </div>
  );
}

function ScreenRetry() {
  const {
    customBtns,
    customRetryScreen,
    customFrame,
    customOverlay,
    textsRetry,
  } = useContext(CustomContext);

  const Button = styled.button`
    background: ${customBtns[2].value};
    color: ${customBtns[1].value};
    border: ${customBtns[7].value} solid ${customBtns[8].value}px;
    border-radius: ${customBtns[9].value}px;
    font-family: "${customBtns[0].value}" !important;
  `;

  const RetryHeader = styled.div`
    font-family: "${customRetryScreen[0].value}" !important;
    color: ${customRetryScreen[1].value};
    font-size: 20px;
    font-weight: 700;
  `;

  const RetrySubtext = styled.div`
    font-family: "${customRetryScreen[2].value}" !important;
    color: ${customRetryScreen[3].value};
  `;

  return (
    <div
      className="scrren1"
      style={{
        background: customOverlay[0].value,
      }}
    >
      <div
        style={{
          background: customFrame[3].value,
          borderRadius: `${customFrame[1].value}px`,
          border: `${customFrame[2].value} solid ${customFrame[0].value}px`,
          paddingBottom: "10px",
          boxShadow: `0px 0px ${customFrame[4].value}px #000000`,
        }}
      >
        <Grid container>
          <Grid className="arrowbox" item xs={6} md={6}>
            <img
              alt=""
              src={arrowBack}
              style={{
                width: "10px",
                height: "auto",
                display: "block !important",
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}></Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <RetryHeader>{textsRetry.RETRY_HEADER}</RetryHeader>
        </Grid>
        <RetrySubtext className="textRetry" container justifyContent="center">
          {textsRetry.RETRY_SUBHEADER}
        </RetrySubtext>
        <Grid container justifyContent="center" direction="row">
          <div
            className="retry1"
            style={{
              borderRadius: `${customRetryScreen[6].value}px`,
              border: `${customRetryScreen[4].value} solid ${customRetryScreen[5].value}px`,
            }}
          ></div>
          <div
            className="retry2"
            style={{
              borderRadius: `${customRetryScreen[6].value}px`,
              border: `${customRetryScreen[4].value} solid ${customRetryScreen[5].value}px`,
            }}
          ></div>
        </Grid>
        <Grid
          container
          className="paddingRetry"
          justifyContent="center"
          direction="row"
        >
          <RetrySubtext className="subImgDesc">
            {textsRetry.RETRY_YOUR_PICTURE}
          </RetrySubtext>
          <RetrySubtext className="subImgDesc">
            {textsRetry.RETRY_IDEAL_PICTURE}
          </RetrySubtext>
        </Grid>
        <RetrySubtext className="textReady" container justifyContent="center">
          {textsRetry.RETRY_MESSAGE_LIGHTING}
        </RetrySubtext>
        <Grid className="boxBtn" container justifyContent="center">
          <Button className="btn-primary">{textsRetry.RETRY_BUTTON}</Button>
        </Grid>
      </div>
    </div>
  );
}

function ScreenFeedback() {
  const { customFrame, customOverlay, textsResult } = useContext(CustomContext);

  // const Button = styled.button`
  //   background: ${customBtns[2].value};
  //   color: ${customBtns[1].value};
  //   border: ${customBtns[7].value} solid ${customBtns[8].value}px;
  //   border-radius: ${customBtns[9].value}px;
  //   font-family: "${customBtns[0].value}" !important;
  // `;

  return (
    <div
      className="scrren3"
      style={{
        background: customOverlay[0].value,
      }}
    >
      <div
        style={{
          background: customFrame[3].value,
          borderRadius: `${customFrame[1].value}px`,
          border: `${customFrame[2].value} solid ${customFrame[0].value}px`,
          boxShadow: `0px 0px ${customFrame[4].value}px #000000`,
          width: `90%`,
          paddingTop: `190px`,
          height: `450px`,
          marginTop: `20px`,
          paddingBottom: `185px`,
        }}
      >
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className="feedbackimg"
            item
            xs={12}
            md={12}
          >
            <img
              alt=""
              src={okCheck}
              style={{
                width: "40px",
                height: "auto",
              }}
            />
          </Grid>
          <Grid className="textFeedbackOk" container justifyContent="center">
            {textsResult.RESULT_SUCCESS_MESSAGE}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

function Screens() {
  const { screen, handleScreen } = useContext(CustomContext);

  return (
    <div className="screens">
      <Carousel
        showStatus={false}
        showArrows={true}
        showIndicators={false}
        selectedItem={screen}
        infiniteLoop={true}
        showThumbs={true}
        onClickThumb={(index) => handleScreen(index)}
      >
        <div className="boxScreen">
          <ScreenReady />
          <img alt="" src={screenReady} />
        </div>
        <div className="boxScreen">
          <ScreenRetry />
          <img alt="" src={screenRetry} />
        </div>
        <div className="boxScreen">
          <ScreenFeedback />
          <img alt="" src={screenfeedbackOk} />
        </div>
      </Carousel>
    </div>
  );
}

export default Screens;
