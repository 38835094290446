import { FormatColorFill } from "@mui/icons-material";
import { Box, Grid, TextField } from "@mui/material";
import { Sketch } from "@uiw/react-color";

import React, { useContext } from "react";

import { CustomContext } from "../../context/CustomContext";

function InputPicker({ section, id, value }) {
  const {
    customBtns,
    setCustomBtnData,
    customGuidance,
    setCustomGuidanceData,
    setCustomReadyScreen,
    customReadyScreen,
    customRetryScreen,
    setCustomRetryScreen,
    customResultScreen,
    setCustomResultScreen,
    customOvalScreen,
    setCustomOvalScreen,
    customFeedback,
    setCustomFeedback,
    customFrame,
    setCustomFrame,
    customOverlay,
    setCustomOverlay,
  } = useContext(CustomContext);

  const [hex, setHex] = React.useState(value);
  const [, setHsva] = React.useState(value);
  const [showPicker, setShowPicker] = React.useState(false);

  const handleValue = (hex) => {
    if (hex) {
      setHex(hex.hex);
      setHsva(hex.hsva);
    }

    if (section === "btns") {
      setCustomBtnData(
        customBtns.map((item) =>
          item.id === id ? { ...item, value: hex.hex } : { ...item },
        ),
      );
    }
    if (section === "guidance") {
      setCustomGuidanceData(
        customGuidance.map((item) =>
          item.id === id ? { ...item, value: hex.hex } : { ...item },
        ),
      );
    }
    if (section === "ready") {
      setCustomReadyScreen(
        customReadyScreen.map((item) =>
          item.id === id ? { ...item, value: hex.hex } : { ...item },
        ),
      );
    }
    if (section === "retry") {
      setCustomRetryScreen(
        customRetryScreen.map((item) =>
          item.id === id ? { ...item, value: hex.hex } : { ...item },
        ),
      );
    }
    if (section === "result") {
      setCustomResultScreen(
        customResultScreen.map((item) =>
          item.id === id ? { ...item, value: hex.hex } : { ...item },
        ),
      );
    }
    if (section === "oval") {
      setCustomOvalScreen(
        customOvalScreen.map((item) =>
          item.id === id ? { ...item, value: hex.hex } : { ...item },
        ),
      );
    }
    if (section === "frame") {
      setCustomFrame(
        customFrame.map((item) =>
          item.id === id ? { ...item, value: hex.hex } : { ...item },
        ),
      );
    }

    if (section === "overlay") {
      setCustomOverlay(
        customOverlay.map((item) =>
          item.id === id ? { ...item, value: hex.hex } : { ...item },
        ),
      );
    }
    if (section === "feedback") {
      setCustomFeedback(
        customFeedback.map((item) =>
          item.id === id ? { ...item, value: hex.hex } : { ...item },
        ),
      );
    }
  };

  return (
    <div style={{ marginTop: "5px" }}>
      <Grid item xs="12" md="12">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <FormatColorFill
            onClick={() => setShowPicker(true)}
            sx={{ color: hex, mr: 1, my: 0.5 }}
          />
          <TextField
            onClick={() => setShowPicker(true)}
            id="input-with-sx"
            variant="standard"
            style={{
              cursor: "pointer !important",
            }}
            value={hex}
          />
        </Box>
      </Grid>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: "0",
          display: showPicker ? "block" : "none",
          left: "0",
          zIndex: 80,
        }}
        onClick={() => setShowPicker(false)}
      ></div>
      <div
        style={{
          position: "absolute",
          display: showPicker ? "block" : "none",
          zIndex: 99,
        }}
      >
        <Sketch
          color={hex}
          onChange={(color) => {
            handleValue(color);
          }}
        />
      </div>
    </div>
  );
}

export default InputPicker;
