export default function inputs() {
  return {
    guidance: [
      {
        id: "guidanceCustomizationBackgroundColors",
        description: "",
        value: "#FFFFFF",
        type: "color",
      },
      {
        id: "guidanceCustomizationForegroundColor",
        description: "",
        value: "#FFFFFF",
        type: "color",
      },
      {
        id: "guidanceCustomizationHeaderFont",
        description: "",
        value: "",
      },
      {
        id: "guidanceCustomizationSubtextFont",
        description: "",
        value: "",
      },
    ],
    btns: [
      {
        id: "guidanceCustomizationButtonFont",
        description: "",
        value: "",
        type: "text",
      },
      {
        id: "guidanceCustomizationButtonTextNormalColor",
        description: "",
        value: "#000000",
        type: "color",
      },
      {
        id: "guidanceCustomizationButtonBackgroundNormalColor",
        description: "",
        value: "#05d758",
        type: "color",
      },
      {
        id: "guidanceCustomizationButtonTextHighlightColor",
        description: "",
        value: "#000000",
        type: "color",
      },
      {
        id: "guidanceCustomizationButtonBackgroundHighlightColor",
        description: "",
        value: "#05d758",
        type: "color",
      },
      {
        id: "guidanceCustomizationButtonTextDisabledColor",
        description: "",
        value: "#FFFFFF",
        type: "color",
      },
      {
        id: "guidanceCustomizationButtonBackgroundDisabledColor",
        description: "",
        value: "#FFFFFF",
        type: "color",
      },
      {
        id: "guidanceCustomizationButtonBorderColor",
        description: "",
        value: "#FFFFFF",
        type: "color",
      },
      {
        id: "guidanceCustomizationButtonBorderWidth",
        description: "",
        value: 0,
      },
      {
        id: "guidanceCustomizationButtonCornerRadius",
        description: "",
        value: 4,
      },
    ],
    readyscreen: [
      {
        id: "guidanceCustomizationReadyScreenHeaderFont",
        description: "",
        value: "#FFFFFF",
        type: "text",
      },
      {
        id: "guidanceCustomizationReadyScreenHeaderTextColor",
        description: "",
        value: "#FFFFFF",
        type: "color",
      },
      {
        id: "guidanceCustomizationReadyScreenHeaderAttributedString",
        description: "",
        value: "#FFFFFF",
        type: "text",
      },
      {
        id: "guidanceCustomizationReadyScreenSubtextFont",
        description: "",
        value: "#FFFFFF",
        type: "text",
      },
      {
        id: "guidanceCustomizationReadyScreenSubtextTextColor",
        description: "",
        value: "#6c3030",
        type: "color",
      },
      {
        id: "guidanceCustomizationReadyScreenSubtextAttributedString",
        description: "",
        value: "#FFFFFF",
        type: "text",
      },
      {
        id: "guidanceCustomizationReadyScreenOvalFillColor",
        description: "",
        value: "#FFFFFF",
        type: "color",
      },
      {
        id: "guidanceCustomizationReadyScreenTextBackgroundColor",
        description: "",
        value: "#FFFFFF",
        type: "color",
      },
      {
        id: "guidanceCustomizationReadyScreenTextBackgroundCornerRadius",
        description: "",
        value: "#FFFFFF",
        type: "color",
      },
    ],
    retryscreen: [],
    resultscreen: [],
    ovalcustom: [],
    framecustom: [],
    overlaycustom: [],
    feedbackcustom: [],
    cancelbutton: [],
    exitanimation: [],
    resultscreenmessage: [],
  };
}
