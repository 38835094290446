import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCyXxDmenljnIhvyU9qrThh5iZcUUzVvhc",
  authDomain: "app-demo-b092a.firebaseapp.com",
  projectId: "app-demo-b092a",
  storageBucket: "app-demo-b092a.appspot.com",
  messagingSenderId: "645771969972",
  appId: "1:645771969972:web:00eb7536f519d3a6091ee1",
  measurementId: "G-C5J11ZMRN4",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const storage = getStorage(app);
