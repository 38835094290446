import { Button, Divider, Grid, Typography } from "@mui/material";
import { CodeBlock, dracula } from "react-code-blocks";
import { CustomContext } from "../../../context/CustomContext";
import { useContext } from "react";
import { hexToRgba } from "@uiw/color-convert";
import { FaSwift } from "react-icons/fa";
import SwitchPlatform from "../../SwitchPlatform";
function GeneratedCodeIos() {
  const {
    customBtns,
    customGuidance,
    customReadyScreen,
    customRetryScreen,
    customResultScreen,
    customOvalScreen,
    customFeedback,
    customFrame,
    customOverlay,
    language,
    setLanguage,
  } = useContext(CustomContext);
  const guidanceMapSwift = () => {
    return customGuidance
      .map(
        (item) =>
          (item.type === "color" &&
            `theme.${item.id} = UIColor(red: ${
              hexToRgba(item.value).r
            }/255, green: ${hexToRgba(item.value).g}/255, blue: ${
              hexToRgba(item.value).b
            }/255, alpha: ${hexToRgba(item.value).a})\n`) ||
          (item.type === "number" && `theme.${item.id} = ${item.value}\n`) ||
          (item.type === "font" &&
            `theme.${
              item.id
            } = UIFont(name: "${item.value.toLowerCase()}", size: 0)\n`),
      )
      .join("");
  };

  const btnsMapSwift = () => {
    return customBtns
      .map((item) => {
        switch (item.type) {
          case "color": {
            const rgba = hexToRgba(item.value);
            return `theme.${item.id} = UIColor(red: ${rgba.r}/255, green: ${rgba.g}/255, blue: ${rgba.b}/255, alpha: ${rgba.a})\n`;
          }
          case "number":
            return `theme.${item.id} = ${item.value}\n`;
          case "font":
            return `theme.${
              item.id
            } = UIFont(name: "${item.value.toLowerCase()}", size: 0)\n`;
          case "cancelButtonLocation": {
            const location = Object.keys(item.value).find(
              (key) => item.value[key] === true,
            );
            return location ? `theme.${item.id} = .${location}\n` : "";
          }
          case "image": {
            const image = item.value;
            return image
              ? `theme.${item.id} = UIImage(data: "${image}")\n`
              : "";
          }
          default:
            return "";
        }
      })
      .join("");
  };

  const readyMapSwift = () => {
    return customReadyScreen
      .map(
        (item) =>
          (item.type === "color" &&
            `theme.${item.id} = UIColor(red: ${
              hexToRgba(item.value).r
            }/255, green: ${hexToRgba(item.value).g}/255, blue: ${
              hexToRgba(item.value).b
            }/255, alpha: ${hexToRgba(item.value).a})\n`) ||
          (item.type === "number" && `theme.${item.id} = ${item.value}\n`) ||
          (item.type === "font" &&
            `theme.${
              item.id
            } = UIFont(name: "${item.value.toLowerCase()}", size: 0)\n`),
      )
      .join("");
  };

  const retryMapSwift = () => {
    return customRetryScreen
      .map(
        (item) =>
          (item.type === "color" &&
            `theme.${item.id} = UIColor(red: ${
              hexToRgba(item.value).r
            }/255, green: ${hexToRgba(item.value).g}/255, blue: ${
              hexToRgba(item.value).b
            }/255, alpha: ${hexToRgba(item.value).a})\n`) ||
          (item.type === "number" && `theme.${item.id} = ${item.value}\n`) ||
          (item.type === "font" &&
            `theme.${
              item.id
            } = UIFont(name: "${item.value.toLowerCase()}", size: 0)\n`),
      )
      .join("");
  };

  const resultMapSwift = () => {
    return customResultScreen
      .map((item) => {
        switch (item.type) {
          case "color": {
            const rgba = hexToRgba(item.value);
            return `theme.${item.id} = UIColor(red: ${rgba.r}/255, green: ${rgba.g}/255, blue: ${rgba.b}/255, alpha: ${rgba.a})\n`;
          }
          case "number":
            return `theme.${item.id} = ${item.value}\n`;

          case "font":
            return `theme.${
              item.id
            } = UIFont(name: "${item.value.toLowerCase()}", size: 0)\n`;

          case "resultScreenCustomizationAnimation":
            return handleAnimationStyle(item);

          default:
            return "";
        }
      })
      .join("");
  };

  const handleAnimationStyle = (item) => {
    let result = "";
    Object.entries(item.value).forEach(([key, subItem]) => {
      switch (key) {
        case "blob":
        case "spinner": {
          result += `theme.${item.id}.${key}Color = UIColor(hex: "${
            subItem[key + "Color"]
          }")\n`;
          result += `theme.${item.id}.checkmarkForegroundColor = UIColor(hex: "${subItem.checkmarkForegroundColor}")\n`;
          result += `theme.${item.id}.checkmarkBackgroundColor = UIColor(hex: "${subItem.checkmarkBackgroundColor}")\n`;
          break;
        }
        case "image": {
          result += `theme.${item.id}.image = UIImage(named: "${subItem.image}")\n`;
          result += `theme.${item.id}.rotationInterval = ${subItem.rotationInterval}\n`;
          result += `theme.${item.id}.checkmarkForegroundColor = UIColor(hex: "${subItem.checkmarkForegroundColor}")\n`;
          result += `theme.${item.id}.checkmarkBackgroundColor = UIColor(hex: "${subItem.checkmarkBackgroundColor}")\n`;
          break;
        }
        case "custom": {
          result += `theme.${item.id}.createActivityIndicatorView = ${subItem.createActivityIndicatorView}\n`;
          result += `theme.${item.id}.createSuccessAnimationView = ${subItem.createSuccessAnimationView}\n`;
          break;
        }
      }
    });
    return result;
  };

  const ovalMapSwift = () => {
    return customOvalScreen
      .map(
        (item) =>
          (item.type === "color" &&
            `theme.${item.id} = UIColor(red: ${
              hexToRgba(item.value).r
            }/255, green: ${hexToRgba(item.value).g}/255, blue: ${
              hexToRgba(item.value).b
            }/255, alpha: ${hexToRgba(item.value).a})\n`) ||
          (item.type === "number" && `theme.${item.id} = ${item.value}\n`) ||
          (item.type === "font" &&
            `theme.${
              item.id
            } = UIFont(name: "${item.value.toLowerCase()}", size: 0)\n`),
      )
      .join("");
  };

  const frameMapSwift = () => {
    return customFrame
      .map(
        (item) =>
          (item.type === "color" &&
            `theme.${item.id} = UIColor(red: ${
              hexToRgba(item.value).r
            }/255, green: ${hexToRgba(item.value).g}/255, blue: ${
              hexToRgba(item.value).b
            }/255, alpha: ${hexToRgba(item.value).a})\n`) ||
          (item.type === "number" && `theme.${item.id} = ${item.value}\n`) ||
          (item.type === "font" &&
            `theme.${
              item.id
            } = UIFont(name: "${item.value.toLowerCase()}", size: 0)\n`),
      )
      .join("");
  };

  const overlayMapSwift = () => {
    return customOverlay
      .map(
        (item) =>
          (item.type === "color" &&
            `theme.${item.id} = UIColor(red: ${
              hexToRgba(item.value).r
            }/255, green: ${hexToRgba(item.value).g}/255, blue: ${
              hexToRgba(item.value).b
            }/255, alpha: ${hexToRgba(item.value).a})\n`) ||
          (item.type === "number" && `theme.${item.id} = ${item.value}\n`) ||
          (item.type === "font" &&
            `theme.${
              item.id
            } = UIFont(name: "${item.value.toLowerCase()}", size: 0)\n`),
      )
      .join("");
  };

  const feedbackMapSwift = () => {
    return customFeedback
      .map((item) => {
        switch (item.type) {
          case "color": {
            const rgba = hexToRgba(item.value);
            return `theme.${item.id} = UIColor(red: ${rgba.r}/255, green: ${rgba.g}/255, blue: ${rgba.b}/255, alpha: ${rgba.a})\n`;
          }
          case "number":
            return `theme.${item.id} = ${item.value}\n`;

          case "font":
            return `theme.${
              item.id
            } = UIFont(name: "${item.value.toLowerCase()}", size: 0)\n`;

          case "livenessShadow":
            return handleLivenessShadow(item);

          default:
            return "";
        }
      })
      .join("");
  };

  const handleLivenessShadow = (item) => {
    const shadow = item.value;
    const rgba = hexToRgba(shadow.color);
    return `theme.${item.id} = Shadow(color: UIColor(red: ${rgba.r}/255, green: ${rgba.g}/255, blue: ${rgba.b}/255, alpha: ${rgba.a}), opacity: ${shadow.opacity}, radius: ${shadow.radius}, offset: CGSize(width: ${shadow.offset.width}, height: ${shadow.offset.height}), insets: UIEdgeInsets(top: ${shadow.insets.top}, left: ${shadow.insets.left}, bottom: ${shadow.insets.bottom}, right: ${shadow.insets.right}))\n`;
  };

  const code = `var theme = Liveness3DTheme(Liveness3DThemeType.light)
 //Guidance
${guidanceMapSwift()}
 //Botões
${btnsMapSwift()}
 //Ready Screen
${readyMapSwift()}
 //Retry Screen
${retryMapSwift()}
 //Result Screen
${resultMapSwift()}
 //Oval
${ovalMapSwift()}
 //Frame
${frameMapSwift()}
 //Overlay
${overlayMapSwift()}
 //Feedback Screen
${feedbackMapSwift()}`;

  return (
    <div className="containerCode">
      <Grid container>
        <Grid item xs="12" md="12" className="codeNavigator">
          <SwitchPlatform />
          <Divider style={{ marginLeft: 15 }} orientation="vertical" flexItem />
          <Button
            className={`secondaryBtn ${
              language === "kotlin" && "secondaryBtnActive"
            }`}
            size="large"
            variant="text"
            startIcon={<FaSwift />}
            onClick={() => setLanguage("kotlin")}
          >
            Swift
          </Button>
        </Grid>

        <Grid className="contentCode" item xs="12" md="12">
          <Typography
            style={{ borderLeft: "3px solid #FFFFFF", paddingLeft: "10px" }}
            variant="h5"
            fontWeight="600"
            fontFamily="Ubuntu"
            marginTop={5}
          >
            1. Inicie o <mark className="markStyle">Liveness3DTheme()</mark>
          </Typography>
          <Typography marginBottom="15px">
            Para iniciar do tema com a classe Liveness3DTheme() do OILiveness3D,
            utilize o exemplo abaixo:
          </Typography>
          <CodeBlock
            text={code}
            language="swift"
            showLineNumbers={12}
            theme={dracula}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default GeneratedCodeIos;
