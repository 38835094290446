import { Grid } from "@mui/material";
import { useContext } from "react";
import { CustomContext } from "../../context/CustomContext";
import ConfigList from "../ConfigList";
import GeneratedCode from "../GeneratedCode";
import GeneratedQrCode from "../GeneratedCode/qrcode";
import Screens from "../Screens";

function CustomPage(homolog) {
  return (
    <Grid container className="App-header" xs={12} md={12}>
      <Grid item className="screens" xs={6} md={6}>
        <Screens />
      </Grid>
      <Grid className="scroll-list" item xs={6} md={6}>
        <ConfigList homolog={homolog} />
      </Grid>
    </Grid>
  );
}

function CodePage() {
  return (
    <Grid container className="App-header" xs={12} md={12}>
      <Grid item className="codeScreen" xs={12} md={12}>
        <GeneratedCode />
      </Grid>
    </Grid>
  );
}

function QRCodePage() {
  return (
    <Grid container className="App-header" xs={12} md={12}>
      <Grid
        item
        className="codeScreen"
        xs={12}
        md={12}
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <GeneratedQrCode />
      </Grid>
    </Grid>
  );
}

function Navigation({ homolog }) {
  const { tab } = useContext(CustomContext);

  return (
    (tab === "custom" && CustomPage(homolog)) ||
    (tab === "code" && CodePage()) ||
    (tab === "qrcode" && QRCodePage())
  );
}

export default Navigation;
