import { Box, Divider, Grid, TextField } from "@mui/material";
import React, { useContext, useId } from "react";

import { CustomContext } from "../../context/CustomContext";

function EditTexts() {
  const {
    textsReady,
    textsRetry,
    textsResult,
    textsFeedback,
    setTextsReady,
    setTextsRetry,
    setTextsResult,
    setTextsFeedback,
    handleScreen,
  } = useContext(CustomContext);

  return (
    <>
      <div
        onClick={() => handleScreen(0)}
        style={{ marginTop: "5px", padding: "25px" }}
      >
        <Grid item xs="12" md="12">
          <Box style={{ display: "flex" }} className="titleTexts">
            Ready Screen
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              READY_HEADER_1:
            </Grid>
            <TextField
              value={textsReady.READY_HEADER_1}
              onChange={(e) =>
                setTextsReady({ ...textsReady, READY_HEADER_1: e.target.value })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              READY_HEADER_2:
            </Grid>
            <TextField
              value={textsReady.READY_HEADER_2}
              onChange={(e) =>
                setTextsReady({ ...textsReady, READY_HEADER_2: e.target.value })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              READY_MESSAGE_1:
            </Grid>
            <TextField
              value={textsReady.READY_MESSAGE_1}
              onChange={(e) =>
                setTextsReady({
                  ...textsReady,
                  READY_MESSAGE_1: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              READY_MESSAGE_2:
            </Grid>
            <TextField
              value={textsReady.READY_MESSAGE_2}
              onChange={(e) =>
                setTextsReady({
                  ...textsReady,
                  READY_MESSAGE_2: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              READY_BUTTON:
            </Grid>
            <TextField
              value={textsReady.READY_BUTTON}
              onChange={(e) =>
                setTextsReady({ ...textsReady, READY_BUTTON: e.target.value })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
      </div>
      <Divider />
      <div
        onClick={() => handleScreen(1)}
        style={{ marginTop: "5px", padding: "25px" }}
      >
        <Grid item xs="12" md="12">
          <Box style={{ display: "flex" }} className="titleTexts">
            Retry Screen
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              RETRY_HEADER:
            </Grid>
            <TextField
              value={textsRetry.RETRY_HEADER}
              onChange={(e) =>
                setTextsRetry({ ...textsRetry, RETRY_HEADER: e.target.value })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              RETRY_SUBHEADER:
            </Grid>
            <TextField
              value={textsRetry.RETRY_SUBHEADER}
              onChange={(e) =>
                setTextsRetry({
                  ...textsRetry,
                  RETRY_SUBHEADER: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              RETRY_MESSAGE_SMILE:
            </Grid>
            <TextField
              value={textsRetry.RETRY_MESSAGE_SMILE}
              onChange={(e) =>
                setTextsRetry({
                  ...textsRetry,
                  RETRY_MESSAGE_SMILE: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              RETRY_MESSAGE_LIGHTING:
            </Grid>
            <TextField
              value={textsRetry.RETRY_MESSAGE_LIGHTING}
              onChange={(e) =>
                setTextsRetry({
                  ...textsRetry,
                  RETRY_MESSAGE_LIGHTING: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              RETRY_MESSAGE_CONTRAST:
            </Grid>
            <TextField
              value={textsRetry.RETRY_MESSAGE_CONTRAST}
              onChange={(e) =>
                setTextsRetry({
                  ...textsRetry,
                  RETRY_MESSAGE_CONTRAST: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              RETRY_YOUR_PICTURE:
            </Grid>
            <TextField
              value={textsRetry.RETRY_YOUR_PICTURE}
              onChange={(e) =>
                setTextsRetry({
                  ...textsRetry,
                  RETRY_YOUR_PICTURE: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              RETRY_IDEAL_PICTURE:
            </Grid>
            <TextField
              value={textsRetry.RETRY_IDEAL_PICTURE}
              onChange={(e) =>
                setTextsRetry({
                  ...textsRetry,
                  RETRY_IDEAL_PICTURE: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              RETRY_BUTTON:
            </Grid>
            <TextField
              value={textsRetry.RETRY_BUTTON}
              onChange={(e) =>
                setTextsRetry({ ...textsRetry, RETRY_BUTTON: e.target.value })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
      </div>

      <Divider />

      <div
        onClick={() => handleScreen(2)}
        style={{ marginTop: "5px", padding: "25px" }}
      >
        <Grid item xs="12" md="12">
          <Box style={{ display: "flex" }} className="titleTexts">
            Result Screen
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              RESULT_UPLOAD_MESSAGE:
            </Grid>
            <TextField
              value={textsResult.RESULT_UPLOAD_MESSAGE}
              onChange={(e) =>
                setTextsResult({
                  ...textsResult,
                  RESULT_UPLOAD_MESSAGE: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              RESULT_SUCCESS_MESSAGE:
            </Grid>
            <TextField
              value={textsResult.RESULT_SUCCESS_MESSAGE}
              onChange={(e) =>
                setTextsResult({
                  ...textsResult,
                  RESULT_SUCCESS_MESSAGE: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
      </div>

      <Divider />

      <div
        onClick={() => handleScreen(0)}
        style={{ marginTop: "5px", padding: "25px" }}
      >
        <Grid item xs="12" md="12">
          <Box style={{ display: "flex" }} className="titleTexts">
            Feedback
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_CENTER_FACE:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_CENTER_FACE}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_CENTER_FACE: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_FACE_NOT_FOUND:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_FACE_NOT_FOUND}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_FACE_NOT_FOUND: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_FACE_NOT_LOOKING_STRAIGHT_AHEAD:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_FACE_NOT_LOOKING_STRAIGHT_AHEAD}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_FACE_NOT_LOOKING_STRAIGHT_AHEAD: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_FACE_NOT_UPRIGHT:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_FACE_NOT_UPRIGHT}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_FACE_NOT_UPRIGHT: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_HOLD_STEADY:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_HOLD_STEADY}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_HOLD_STEADY: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_MOVE_PHONE_AWAY:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_MOVE_PHONE_AWAY}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_MOVE_PHONE_AWAY: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_MOVE_PHONE_CLOSER:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_MOVE_PHONE_CLOSER}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_MOVE_PHONE_CLOSER: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_MOVE_PHONE_TO_EYE_LEVEL:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_MOVE_PHONE_TO_EYE_LEVEL}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_MOVE_PHONE_TO_EYE_LEVEL: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_USE_EVEN_LIGHTING:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_USE_EVEN_LIGHTING}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_USE_EVEN_LIGHTING: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_FRAME_YOUR_FACE:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_FRAME_YOUR_FACE}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_FRAME_YOUR_FACE: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_HOLD_STEADY_1:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_HOLD_STEADY_1}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_HOLD_STEADY_1: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_HOLD_STEADY_2:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_HOLD_STEADY_2}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_HOLD_STEADY_2: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_HOLD_STEADY_3:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_HOLD_STEADY_3}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_HOLD_STEADY_3: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_REMOVE_DARK_GLASSES:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_REMOVE_DARK_GLASSES}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_REMOVE_DARK_GLASSES: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_NEUTRAL_EXPRESSION:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_NEUTRAL_EXPRESSION}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_NEUTRAL_EXPRESSION: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_CONDITIONS_TOO_BRIGHT:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_CONDITIONS_TOO_BRIGHT}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_CONDITIONS_TOO_BRIGHT: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs="12" md="12" className="separationTexts">
          <Box>
            <Grid item xs="12" md="12" className="infoTexts">
              FEEDBACK_BRIGHTEN_YOUR_ENVIRONMENT:
            </Grid>
            <TextField
              value={textsFeedback.FEEDBACK_BRIGHTEN_YOUR_ENVIRONMENT}
              onChange={(e) =>
                setTextsFeedback({
                  ...textsFeedback,
                  FEEDBACK_BRIGHTEN_YOUR_ENVIRONMENT: e.target.value,
                })
              }
              id={useId}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
      </div>
    </>
  );
}

export default EditTexts;
