import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { CodeBlock, dracula } from "react-code-blocks";
import { CustomContext } from "../../../context/CustomContext";
import { useContext } from "react";
import { TbBrandJavascript } from "react-icons/tb";

import SwitchPlatform from "../../SwitchPlatform";
function GeneratedCodeRN() {
  const {
    customBtns,
    customGuidance,
    customReadyScreen,
    customRetryScreen,
    customResultScreen,
    customOvalScreen,
    customFeedback,
    customFrame,
    customOverlay,
    language,
    setLanguage,
  } = useContext(CustomContext);

  const guidanceMapKotlin = () => {
    return customGuidance
      .map(
        (item) =>
          (item.type === "color" &&
            `                 ${item.id}:"${item.value.toLowerCase()}",\n`) ||
          (item.type === "number" &&
            `                 ${item.id}: ${item.value},\n`) ||
          (item.type === "font" && ``),
      )
      .join("");
  };

  const btnsMapKotlin = () => {
    return customBtns
      .map(
        (item) =>
          (item.type === "color" &&
            `                 ${item.id}:"${item.value.toLowerCase()}",\n`) ||
          (item.type === "number" &&
            `                 ${item.id}: ${item.value},\n`) ||
          (item.type === "font" && ``),
      )
      .join("");
  };

  const readyMapKotlin = () => {
    return customReadyScreen
      .map(
        (item) =>
          (item.type === "color" &&
            `                 ${item.id}:"${item.value.toLowerCase()}",\n`) ||
          (item.type === "number" &&
            `                 ${item.id}:${item.value},\n`) ||
          (item.type === "font" && ``),
      )
      .join("");
  };

  const retryMapKotlin = () => {
    return customRetryScreen
      .map(
        (item) =>
          (item.type === "color" &&
            `                 ${item.id}:"${item.value.toLowerCase()}",\n`) ||
          (item.type === "number" &&
            `                 ${item.id}: ${item.value},\n`) ||
          (item.type === "font" && ``),
      )
      .join("");
  };

  const resultMapKotlin = () => {
    return customResultScreen
      .map(
        (item) =>
          (item.type === "color" &&
            `                 ${item.id}:"${item.value.toLowerCase()}",\n`) ||
          (item.type === "number" &&
            `                 ${item.id}: ${item.value},\n`) ||
          (item.type === "font" && ``),
      )
      .join("");
  };

  const ovalMapKotlin = () => {
    return customOvalScreen
      .map(
        (item) =>
          (item.type === "color" &&
            `                 ${item.id}:"${item.value.toLowerCase()}",\n`) ||
          (item.type === "number" &&
            `                 ${item.id}: ${item.value},\n`) ||
          (item.type === "font" && ``),
      )
      .join("");
  };

  const frameMapKotlin = () => {
    return customFrame
      .map(
        (item) =>
          (item.type === "color" &&
            `                 ${item.id}:"${item.value.toLowerCase()}",\n`) ||
          (item.type === "number" &&
            `                 ${item.id}: ${item.value},\n`) ||
          (item.type === "font" && ``),
      )
      .join("");
  };

  const overlayMapKotlin = () => {
    return customOverlay
      .map(
        (item) =>
          (item.type === "color" &&
            `                 ${item.id}:"${item.value.toLowerCase()}",\n`) ||
          (item.type === "number" &&
            `                 ${item.id}: ${item.value},\n`) ||
          (item.type === "font" && ``),
      )
      .join("");
  };

  const feedbackMapKotlin = () => {
    return customFeedback
      .map(
        (item) =>
          (item.type === "color" &&
            `                 ${item.id}:"${item.value.toLowerCase()}",\n`) ||
          (item.type === "number" &&
            `                 ${item.id}: ${item.value},\n`) ||
          (item.type === "font" && ``),
      )
      .join("");
  };

  // FOnts
  const guidanceMapFonts = () => {
    return customGuidance
      .map(
        (item) =>
          (item.type === "color" && ` `) ||
          (item.type === "number" && ` `) ||
          (item.type === "font" &&
            `                 ${item.id}:"${item.value.toLowerCase()}",\n`),
      )
      .join("");
  };

  const btnsMapFonts = () => {
    return customBtns
      .map(
        (item) =>
          (item.type === "color" && ` `) ||
          (item.type === "number" && ` `) ||
          (item.type === "font" &&
            `                  ${item.id}:"${item.value.toLowerCase()}",\n`),
      )
      .join("");
  };

  const readyMapFonts = () => {
    return customReadyScreen
      .map(
        (item) =>
          (item.type === "color" && ` `) ||
          (item.type === "number" && ` `) ||
          (item.type === "font" &&
            `                ${item.id}:"${item.value.toLowerCase()}",\n`),
      )
      .join("");
  };

  const retryMapFonts = () => {
    return customRetryScreen
      .map(
        (item) =>
          (item.type === "color" && ` `) ||
          (item.type === "number" && ` `) ||
          (item.type === "font" &&
            `                ${item.id}:"${item.value.toLowerCase()}",\n`),
      )
      .join("");
  };

  const resultMapFonts = () => {
    return customResultScreen
      .map(
        (item) =>
          (item.type === "color" && ` `) ||
          (item.type === "number" && ` `) ||
          (item.type === "font" &&
            `          ${item.id}:"${item.value.toLowerCase()}",\n`),
      )
      .join("");
  };

  const feedbackMapFonts = () => {
    return customFeedback
      .map(
        (item) =>
          (item.type === "color" && ` `) ||
          (item.type === "number" && ` `) ||
          (item.type === "font" &&
            `               ${item.id}:"${item.value.toLowerCase()}",\n`),
      )
      .join("");
  };

  const code = `           const theme: ThemeType {
                  //Guidance
   ${guidanceMapKotlin().substring(3)}
                  //Botões
   ${btnsMapKotlin().substring(3)}
                  //Ready Screen
   ${readyMapKotlin().substring(3)}
                  //Retry Screen
   ${retryMapKotlin().substring(3)}
                  //Result Screen
   ${resultMapKotlin().substring(3)}
                  //Oval
   ${ovalMapKotlin().substring(3)}
                  //Frame
   ${frameMapKotlin().substring(3)}
                  //Overlay
   ${overlayMapKotlin().substring(3)}
                  //Feedback Screen
   ${feedbackMapKotlin().substring(3)}
             }`;

  const fonts = `           const fonts: FontsType {
                //Guidance
${guidanceMapFonts().substring(2)}
                //Botões
${btnsMapFonts().substring(2)}
                //Ready Screen
${readyMapFonts()}
                //Retry Screen
${retryMapFonts()}
                //Result Screen
${resultMapFonts().substring(2)}
                //Feedback Screen
${feedbackMapFonts().substring(2)}
         }`;

  return (
    <div className="containerCode">
      <Grid container>
        <Grid item xs="12" md="12" className="codeNavigator">
          <SwitchPlatform />
          <Divider style={{ marginLeft: 15 }} orientation="vertical" flexItem />
          <Button
            className={`secondaryBtn ${
              language === "kotlin" && "secondaryBtnActive"
            }`}
            size="large"
            variant="text"
            startIcon={<TbBrandJavascript />}
            onClick={() => setLanguage("kotlin")}
          >
            TypeScript
          </Button>
        </Grid>

        <Grid className="contentCode" item xs="12" md="12">
          <Grid item xs="12" md="12" className="textBox">
            <Typography
              style={{ borderLeft: "3px solid #FFFFFF", paddingLeft: "10px" }}
              variant="h5"
              fontWeight="600"
              fontFamily="Ubuntu"
            >
              1. Inicie o <mark className="markStyle">theme</mark>
            </Typography>
            <Typography>
              Para iniciar, crie um{" "}
              <mark className="markStyle">Objeto JSON</mark> do tema com a chave{" "}
              <mark className="markStyle">theme</mark> dentro do objeto de
              options.
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="end"
            marginBottom="-65px"
            paddingLeft="25px"
            paddingRight={1}
          >
            <Box
              component="span"
              marginBottom="15px"
              sx={{
                p: 1,
                border: "2px dashed #FFF",
                borderRadius: "7px",
                color: "#FFF",
              }}
            >
              theme key
            </Box>
          </Grid>
          <CodeBlock
            text={code}
            language="javascript"
            showLineNumbers={12}
            theme={dracula}
          />
          <Grid item xs="12" md="12" className="textBox">
            <Typography
              style={{ borderLeft: "3px solid #FFFFFF", paddingLeft: "10px" }}
              variant="h5"
              fontWeight="600"
              fontFamily="Ubuntu"
            >
              2. Inicie o <mark className="markStyle">fonts</mark>
            </Typography>
            <Typography>
              Para iniciar, crie um{" "}
              <mark className="markStyle">Objeto JSON</mark> das fonts com a
              chave <mark className="markStyle">fonts</mark> dentro do objeto de
              options.
            </Typography>
          </Grid>
          <CodeBlock
            text={fonts}
            language="javascript"
            showLineNumbers={12}
            theme={dracula}
          />
          <Typography
            style={{ borderLeft: "3px solid #FFFFFF", paddingLeft: "10px" }}
            variant="h5"
            fontWeight="600"
            fontFamily="Ubuntu"
            marginTop={5}
          >
            3. Importar as Fontes
          </Typography>
          <Typography>
            Coloque os arquivos de fontes que deseja na pasta de assets
            <mark className="markStyle">res/fonts.xml</mark>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default GeneratedCodeRN;
