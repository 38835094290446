import { Button, Divider } from "@mui/material";
import { Code, FormatColorFill, QrCode } from "@mui/icons-material";
import { CustomContext } from "../../context/CustomContext";
import { useContext } from "react";
function Menu() {
  const { tab, setTab } = useContext(CustomContext);

  return (
    <div className="menuOptions">
      <Divider style={{ marginLeft: 15 }} orientation="vertical" flexItem />
      <Button
        className={`btn-default ${tab === "custom" && "btn-default-selected"}`}
        variant="outlined"
        startIcon={<FormatColorFill />}
        onClick={() => setTab("custom")}
      >
        Customizar
      </Button>
      <Button
        className={`btn-default ${tab === "code" && "btn-default-selected"}`}
        startIcon={<Code />}
        variant="outlined"
        onClick={() => setTab("code")}
      >
        Código
      </Button>
      <Button
        className={`btn-default ${tab === "qrcode" && "btn-default-selected"}`}
        variant="outlined"
        onClick={() => setTab("qrcode")}
      >
        <QrCode />
      </Button>
    </div>
  );
}

export default Menu;
