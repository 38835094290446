import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useContext } from "react";

import { CustomContext } from "../../context/CustomContext";

const customSelectedStyle = {
  "&.Mui-selected": {
    backgroundColor: "#1748cd",
    color: "white",
  },
};

function InputToggleButton({ section, id }) {
  const [buttonLocation, setButtonLocation] = React.useState("");
  const { customBtns, setCustomBtnData, isIsolated } =
    useContext(CustomContext);

  const handleValue = (newLocation) => {
    if (section === "btns") {
      setCustomBtnData(
        customBtns.map((item) => {
          if (item.id === id) {
            const newValue = {
              topLeft: newLocation === "topLeft",
              topRight: newLocation === "topRight",
              disabled: newLocation === "disabled",
            };

            return { ...item, value: newValue };
          }
          return item;
        }),
      );
    } else {
      console.log("Unknown section", section);
    }
  };

  const handleChange = (event, newLocation) => {
    if (newLocation !== null) {
      setButtonLocation(newLocation);
      handleValue(newLocation);
    }
  };

  return (
    <div>
      {isIsolated && (
        <Grid item xs={12} md={12}>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={buttonLocation}
            exclusive
            onChange={handleChange}
            aria-label="Button location"
          >
            <ToggleButton sx={customSelectedStyle} value="topLeft">
              Top Left
            </ToggleButton>
            <ToggleButton sx={customSelectedStyle} value="topRight">
              Top Right
            </ToggleButton>
            <ToggleButton sx={customSelectedStyle} value="disabled">
              Disabled
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
    </div>
  );
}

export default InputToggleButton;
