import { createContext, useContext, useEffect, useState } from "react";
import {
  getDocs,
  collection,
  updateDoc,
  addDoc,
  doc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  getMetadata,
} from "firebase/storage";
import { db, storage } from "../infra/firebase";
import { format } from "date-fns";

import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

export const FIRESTORE_CUSTOMIZATION_COLLECTION = "iframe-customization";
export const FIRESTORE_ANDROID_DOCUMENT = "android-3d-theme";
export const FIRESTORE_IOS_DOCUMENT = "ios-3d-theme-images";
export const FIRESTORE_MONOLITH_DOCUMENT = "3d-theme";

const IframeCustomizationContext = createContext({});

export function useIframeCustomization() {
  const context = useContext(IframeCustomizationContext);

  if (!context) {
    throw new Error(
      "useIframeCustomization must be used within a IframeCustomizationProvider",
    );
  }

  return context;
}

export const IframeCustomizationProvider = ({ children }) => {
  const [customFireStoreTheme, setCustomFireStoreTheme] = useState({});
  const [isImageUploading, setIsImageUploading] = useState(false);

  const updateDocument = async (data) => {
    console.log(data);
    if (data) {
      try {
        const documentRef = doc(
          db,
          FIRESTORE_CUSTOMIZATION_COLLECTION,
          FIRESTORE_MONOLITH_DOCUMENT,
        );

        await updateDoc(documentRef, data);

        toast("Tema salvo com sucesso!", { type: "success" });
        setCustomFireStoreTheme(data);
      } catch (error) {
        toast("Erro ao salvar tema!", { type: "error" });
      }
    }
  };

  function addImageTimestampSuffix(file) {
    if (!file || !file.name) {
      toast("O arquivo fornecido é inválido ou não possui um nome.", {
        type: "error",
      });
      setIsImageUploading(false);
      return;
    }
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts.pop();
    const fileNameWithoutExtension = fileNameParts.join(".");
    const timestamp = new Date().getTime();

    return `${fileNameWithoutExtension}-${timestamp}.${fileExtension}`;
  }

  const collectionRef = collection(db, FIRESTORE_CUSTOMIZATION_COLLECTION);

  const uploadImage = async (currentImage, session) => {
    if (currentImage) {
      setIsImageUploading(true);
      const mountImageRef = ref(
        storage,
        `l3d-ios-theme/${addImageTimestampSuffix(currentImage)}`,
      );

      try {
        const snapshot = await uploadBytes(mountImageRef, currentImage);
        const downloadURL = await getDownloadURL(snapshot.ref);

        const docData = {
          session,
          image: downloadURL,
          date: new Date().getTime(),
        };

        await addDoc(collectionRef, docData);

        toast("Imagem enviada para o firebase com sucesso", {
          type: "success",
        });

        return {
          currentImage: downloadURL,
          downloadURL: downloadURL,
        };
      } catch (e) {
        toast("Erro ao enviar imagem para o firebase", { type: "error" });
        console.log(e);
      } finally {
        setIsImageUploading(false);
      }
    }
  };
  const generateFileName = () => {
    const now = new Date();
    return `jsonCustom_${format(now, "yyyyMMdd_HHmmss")}.json`;
  };

  useEffect(() => {
    async function fetchThemeDocuments() {
      const querySnapshot = await getDocs(
        collection(db, FIRESTORE_CUSTOMIZATION_COLLECTION),
      );

      await querySnapshot.forEach((document) => {
        if (document.id === FIRESTORE_MONOLITH_DOCUMENT) {
          setCustomFireStoreTheme(document.data());
        }
      });
    }

    fetchThemeDocuments();
  }, []);

  const [uploadingJson, setUploadingJson] = useState(false);

  const handleUploadJson = async (json) => {
    const fileName = generateFileName();
    const jsonString = JSON.stringify(json);
    const jsonBlob = new Blob([jsonString], { type: "application/json" });
    const mountJson = ref(storage, `custom-json/${fileName}`);

    try {
      setUploadingJson(true);

      const snapshot = await uploadBytes(mountJson, jsonBlob);
      const url = await getDownloadURL(snapshot.ref);

      const jsonData = {
        session: "json",
        image: url,
        date: new Date().getTime(),
      };

      await addDoc(collectionRef, jsonData);

      return {
        jsonURL: url,
        fileName: fileName,
      };
    } catch (error) {
      toast("Erro ao enviar o conteúdo para o Firebase Storage!", {
        type: "error",
      });

      return {
        error: error.message,
      };
    } finally {
      setUploadingJson(false);
    }
  };

  const checkFileExistence = async (fileName) => {
    try {
      const jsonRef = ref(storage, `custom-json/${fileName}`);
      await getMetadata(jsonRef); // Tente obter os metadados, isso verificará a existência do arquivo.
      return true;
    } catch (error) {
      console.error("Arquivo não encontrado:", error);
      return false;
    }
  };

  const handleDeleteJson = async (fileName) => {
    try {
      const fileExists = await checkFileExistence(fileName);

      if (fileExists) {
        const jsonRef = ref(storage, `custom-json/${fileName}`);
        await deleteObject(jsonRef);

        return {
          success: true,
        };
      } else {
        return {
          success: true, // Pode ser considerado um sucesso se o arquivo não existir.
        };
      }
    } catch (error) {
      console.error("Erro ao deletar o arquivo do Firebase Storage:", error);

      return {
        error: "Erro ao deletar o arquivo do Firebase Storage",
      };
    }
  };

  const contextValues = {
    customFireStoreTheme,
    isImageUploading,
    updateDocument,
    uploadImage,
    handleUploadJson,
    uploadingJson,
    handleDeleteJson,
  };

  return (
    <IframeCustomizationContext.Provider value={contextValues}>
      {children}
    </IframeCustomizationContext.Provider>
  );
};
