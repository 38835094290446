import logoOiti from "../../assets/img/logo.png";
function Logo() {
  return (
    <div>
      <img alt="Oiti" src={logoOiti} />
    </div>
  );
}

export default Logo;
