import { Button, Grid, IconButton } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import PhotoIcon from "@mui/icons-material/Photo";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";

import { CustomContext } from "../../context/CustomContext";
import { useIframeCustomization } from "../../context/IframeCustomizationContext";

function InputImage({ section, id }) {
  const {
    customBtns,
    setCustomBtnData,
    customResultScreen,
    setCustomResultScreen,
    isIsolated,
  } = useContext(CustomContext);

  const [currentImage, setCurrentImage] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const { uploadImage, isImageUploading } = useIframeCustomization();

  const upload = (currentImage, section) => {
    uploadImage(currentImage, section).then((result) => {
      setCurrentImage(result.currentImage);
      setDownloadUrl(result.downloadURL);
    });
  };

  const handleValue = () => {
    switch (section) {
      case "btns":
        setCustomBtnData(
          customBtns.map((item) =>
            item.id === id ? { ...item, value: downloadUrl } : item,
          ),
        );
        break;
      case "result":
        setCustomResultScreen(
          customResultScreen.map((item) => {
            if (item.id === id) {
              const updatedItem = { ...item };

              if (!updatedItem.value) {
                updatedItem.value = {};
              } else {
                updatedItem.value = { ...updatedItem.value };
              }

              if (!updatedItem.value.image) {
                updatedItem.value.image = {};
              } else {
                updatedItem.value.image = { ...updatedItem.value.image };
              }

              updatedItem.value.image.image = downloadUrl;

              return updatedItem;
            }
            return item;
          }),
        );
        break;
      default:
        console.log("Unknown section", section);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setCurrentImage(e.target.files[0]);
    }
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (downloadUrl !== null) {
      handleValue();
    }
  }, [downloadUrl]);

  return (
    <div>
      {isIsolated && (
        <Grid item xs={12} md={12} marginTop={1}>
          <Grid xs={12} md={12} container spacing={2} marginBottom={2}>
            {currentImage === null && (
              <Grid item xs={12} md={12}>
                <input
                  ref={fileInputRef}
                  type="file"
                  id="filled-image"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  accept="image/*"
                />
                <Button
                  variant="outlined"
                  component="span"
                  fullWidth
                  size="small"
                  onClick={handleButtonClick}
                  startIcon={<PhotoIcon />}
                  sx={{ maxWidth: "250px" }}
                >
                  Escolher imagem
                </Button>
              </Grid>
            )}

            {currentImage !== null && (
              <>
                <Grid item xs={12} md={6}>
                  <img
                    src={
                      currentImage === downloadUrl
                        ? downloadUrl
                        : URL.createObjectURL(currentImage)
                    }
                    alt="preview"
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} marginTop={1}>
                  <IconButton
                    onClick={() => setCurrentImage(null)}
                    color="primary"
                    disabled={isImageUploading}
                  >
                    <CloseIcon fontSize={"large"} />
                  </IconButton>
                </Grid>
              </>
            )}
          </Grid>
          <LoadingButton
            fullWidth
            sx={{ maxWidth: "250px" }}
            size="small"
            onClick={() => upload(currentImage, section)}
            endIcon={
              currentImage === downloadUrl && currentImage !== null ? (
                <CheckIcon />
              ) : (
                <SendIcon />
              )
            }
            loading={isImageUploading}
            loadingPosition="start"
            variant="outlined"
            disabled={currentImage === downloadUrl || currentImage === null}
          >
            {currentImage === downloadUrl && currentImage !== null
              ? "Imagem já enviada"
              : "Enviar imagem"}
          </LoadingButton>
        </Grid>
      )}
    </div>
  );
}

export default InputImage;
