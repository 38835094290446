import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { CodeBlock, dracula } from "react-code-blocks";
import { CustomContext } from "../../../context/CustomContext";
import { useContext } from "react";
import { TbBrandKotlin } from "react-icons/tb";

import SwitchPlatform from "../../SwitchPlatform";
function GeneratedCodeAndroid() {
  const {
    customBtns,
    customGuidance,
    customReadyScreen,
    customRetryScreen,
    customResultScreen,
    customOvalScreen,
    customFeedback,
    customFrame,
    customOverlay,
    language,
    setLanguage,
  } = useContext(CustomContext);

  // Guidance ap
  const guidanceMapXML = () => {
    const map = customGuidance.map((item) =>
      item.type === "color"
        ? `       <color name="${item.id.toLowerCase()}">${
            item.value
          }</color>\n`
        : null,
    );
    return map.join("");
  };

  // Btns Map
  const btnsMapXML = () => {
    return customBtns
      .map((item) =>
        item.type === "color"
          ? `       <color name="${item.id.toLowerCase()}">${
              item.value
            }</color>\n`
          : "",
      )
      .join("");
  };

  const readyXML = () => {
    return customReadyScreen
      .map((item) =>
        item.type === "color"
          ? `       <color name="${item.id.toLowerCase()}">${
              item.value
            }</color>\n`
          : "",
      )
      .join("");
  };

  const retryXML = () => {
    return customRetryScreen
      .map((item) =>
        item.type === "color"
          ? `       <color name="${item.id.toLowerCase()}">${
              item.value
            }</color>\n`
          : "",
      )
      .join("");
  };

  const resultXML = () => {
    return customResultScreen
      .map((item) =>
        item.type === "color"
          ? `       <color name="${item.id.toLowerCase()}">${
              item.value
            }</color>\n`
          : "",
      )
      .join("");
  };

  const ovalXML = () => {
    return customOvalScreen
      .map((item) =>
        item.type === "color"
          ? `       <color name="${item.id.toLowerCase()}">${
              item.value
            }</color>\n`
          : "",
      )
      .join("");
  };

  const frameXML = () => {
    return customFrame
      .map((item) =>
        item.type === "color"
          ? `       <color name="${item.id.toLowerCase()}">${
              item.value
            }</color>\n`
          : "",
      )
      .join("");
  };

  const overlayXML = () => {
    return customOverlay
      .map((item) =>
        item.type === "color"
          ? `       <color name="${item.id.toLowerCase()}">${
              item.value
            }</color>\n`
          : "",
      )
      .join("");
  };

  const feedbackXML = () => {
    return customFeedback
      .map((item) =>
        item.type === "color"
          ? `       <color name="${item.id.toLowerCase()}">${
              item.value
            }</color>\n`
          : "",
      )
      .join("");
  };

  const xml = `<?xml version="1.0" encoding="utf-8"?>
    <resources>
    <!-- Guidance -->
${guidanceMapXML()}
    <!-- Botões -->
${btnsMapXML()}
    <!-- Ready Screen -->
${readyXML()}
    <!-- Retry Screen -->
${retryXML()}
    <!-- Result Screen -->
${resultXML()}
    <!-- Oval Custom -->
${ovalXML()}
    <!-- Frame Custom -->
${frameXML()}
    <!-- Overlay Custom -->
${overlayXML()}
    <!-- Feedback Custom -->
${feedbackXML()}
    </resources>`;

  const guidanceMapKotlin = () => {
    return customGuidance
      .map(
        (item) =>
          (item.type === "color" &&
            `                 .${
              item.id
            }(ContextCompat.getColor(this,R.color.${item.id.toLowerCase()}))\n`) ||
          (item.type === "number" &&
            `                 .${item.id}(${item.value})\n`) ||
          (item.type === "font" &&
            `                 .${
              item.id
            }(Typeface.createFromAsset(assets, "fonts/${item.value.toLowerCase()}.ttf"))\n`),
      )
      .join("");
  };

  const btnsMapKotlin = () => {
    return customBtns
      .map(
        (item) =>
          (item.type === "color" &&
            `                 .${
              item.id
            }(ContextCompat.getColor(this,R.color.${item.id.toLowerCase()}))\n`) ||
          (item.type === "number" &&
            `                 .${item.id}(${item.value})\n`) ||
          (item.type === "font" &&
            `                 .${
              item.id
            }(Typeface.createFromAsset(assets, "fonts/${item.value.toLowerCase()}.ttf"))\n`),
      )
      .join("");
  };

  const readyMapKotlin = () => {
    return customReadyScreen
      .map(
        (item) =>
          (item.type === "color" &&
            `                 .${
              item.id
            }(ContextCompat.getColor(this,R.color.${item.id.toLowerCase()}))\n`) ||
          (item.type === "number" &&
            `                 .${item.id}(${item.value})\n`) ||
          (item.type === "font" &&
            `                 .${
              item.id
            }(Typeface.createFromAsset(assets, "fonts/${item.value.toLowerCase()}.ttf"))\n`),
      )
      .join("");
  };

  const retryMapKotlin = () => {
    return customRetryScreen
      .map(
        (item) =>
          (item.type === "color" &&
            `                 .${
              item.id
            }(ContextCompat.getColor(this,R.color.${item.id.toLowerCase()}))\n`) ||
          (item.type === "number" &&
            `                 .${item.id}(${item.value})\n`) ||
          (item.type === "font" &&
            `                 .${
              item.id
            }(Typeface.createFromAsset(assets, "fonts/${item.value.toLowerCase()}.ttf"))\n`),
      )
      .join("");
  };

  const resultMapKotlin = () => {
    return customResultScreen
      .map(
        (item) =>
          (item.type === "color" &&
            `                 .${
              item.id
            }(ContextCompat.getColor(this,R.color.${item.id.toLowerCase()}))\n`) ||
          (item.type === "number" &&
            `                 .${item.id}(${item.value})\n`) ||
          (item.type === "font" &&
            `                 .${
              item.id
            }(Typeface.createFromAsset(assets, "fonts/${item.value.toLowerCase()}.ttf"))\n`),
      )
      .join("");
  };

  const ovalMapKotlin = () => {
    return customOvalScreen
      .map(
        (item) =>
          (item.type === "color" &&
            `                 .${
              item.id
            }(ContextCompat.getColor(this,R.color.${item.id.toLowerCase()}))\n`) ||
          (item.type === "number" &&
            `                 .${item.id}(${item.value})\n`) ||
          (item.type === "font" &&
            `                 .${
              item.id
            }(Typeface.createFromAsset(assets, "fonts/${item.value.toLowerCase()}.ttf"))\n`),
      )
      .join("");
  };

  const frameMapKotlin = () => {
    return customFrame
      .map(
        (item) =>
          (item.type === "color" &&
            `                 .${
              item.id
            }(ContextCompat.getColor(this,R.color.${item.id.toLowerCase()}))\n`) ||
          (item.type === "number" &&
            `                 .${item.id}(${item.value})\n`) ||
          (item.type === "font" &&
            `                 .${
              item.id
            }(Typeface.createFromAsset(assets, "fonts/${item.value.toLowerCase()}.ttf"))\n`),
      )
      .join("");
  };

  const overlayMapKotlin = () => {
    return customOverlay
      .map(
        (item) =>
          (item.type === "color" &&
            `                 .${
              item.id
            }(ContextCompat.getColor(this,R.color.${item.id.toLowerCase()}))\n`) ||
          (item.type === "number" &&
            `                 .${item.id}(${item.value})\n`) ||
          (item.type === "font" &&
            `                 .${
              item.id
            }(Typeface.createFromAsset(assets, "fonts/${item.value.toLowerCase()}.ttf"))\n`),
      )
      .join("");
  };

  const feedbackMapKotlin = () => {
    return customFeedback
      .map(
        (item) =>
          (item.type === "color" &&
            `                 .${
              item.id
            }(ContextCompat.getColor(this,R.color.${item.id.toLowerCase()}))\n`) ||
          (item.type === "number" &&
            `                 .${item.id}(${item.value})\n`) ||
          (item.type === "font" &&
            `                 .${
              item.id
            }(Typeface.createFromAsset(assets, "fonts/${item.value.toLowerCase()}.ttf"))\n`),
      )
      .join("");
  };

  const code = `val liveness3DTheme =
          Liveness3DTheme
                  .Builder()
                  //Guidance
   ${guidanceMapKotlin().substring(3)}
                  //Botões
   ${btnsMapKotlin().substring(3)}
                  //Ready Screen
   ${readyMapKotlin().substring(3)}
                  //Retry Screen
   ${retryMapKotlin().substring(3)}
                  //Result Screen
   ${resultMapKotlin().substring(3)}
                  //Oval
   ${ovalMapKotlin().substring(3)}
                  //Frame
   ${frameMapKotlin().substring(3)}
                  //Overlay
   ${overlayMapKotlin().substring(3)}
                  //Feedback Screen
   ${feedbackMapKotlin().substring(3)}
                  .build()`;

  return (
    <div className="containerCode">
      <Grid container>
        <Grid item xs="12" md="12" className="codeNavigator">
          <SwitchPlatform />
          <Divider style={{ marginLeft: 15 }} orientation="vertical" flexItem />
          <Button
            className={`secondaryBtn ${
              language === "kotlin" && "secondaryBtnActive"
            }`}
            size="large"
            variant="text"
            startIcon={<TbBrandKotlin />}
            onClick={() => setLanguage("kotlin")}
          >
            Kotlin
          </Button>
        </Grid>

        <Grid className="contentCode" item xs="12" md="12">
          <Grid item xs="12" md="12" className="textBox">
            <Typography
              style={{ borderLeft: "3px solid #FFFFFF", paddingLeft: "10px" }}
              variant="h5"
              fontWeight="600"
              fontFamily="Ubuntu"
            >
              1. XML de Cores
            </Typography>
            <Typography>
              Verifique se existe um aquivo XML referente as cores do android,
              localizado em
              <mark className="markStyle">res/values/colors.xml</mark>, caso não
              exista criar o arquivo com o código abaixo;
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="end"
            marginBottom="-65px"
            paddingLeft="25px"
            paddingRight={1}
          >
            <Box
              component="span"
              marginBottom="15px"
              sx={{
                p: 1,
                border: "2px dashed #FFF",
                borderRadius: "7px",
                color: "#FFF",
              }}
            >
              colors.xml
            </Box>
          </Grid>
          <CodeBlock
            text={xml}
            language="html"
            showLineNumbers={12}
            theme={dracula}
          />
          <Typography
            style={{ borderLeft: "3px solid #FFFFFF", paddingLeft: "10px" }}
            variant="h5"
            fontWeight="600"
            fontFamily="Ubuntu"
            marginTop={5}
          >
            2. Importar as Fontes
          </Typography>
          <Typography>
            Coloque os arquivos de fontes que deseja na pasta de assets
            <mark className="markStyle">res/fonts.xml</mark>
          </Typography>
          <Typography
            style={{ borderLeft: "3px solid #FFFFFF", paddingLeft: "10px" }}
            variant="h5"
            fontWeight="600"
            fontFamily="Ubuntu"
            marginTop={5}
          >
            3. Inicie o <mark className="markStyle">Liveness3DTheme()</mark>
          </Typography>
          <Typography marginBottom="15px">
            Para iniciar o <mark className="markStyle">Builder()</mark> do tema
            com a classe <mark className="markStyle">Liveness3DTheme()</mark> do
            liveness3d-sdk, utilize o exemplo abaixo:
          </Typography>
          <CodeBlock
            text={code}
            language="kotlin"
            showLineNumbers={12}
            theme={dracula}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default GeneratedCodeAndroid;
