import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import logoSVG from "../../../assets/img/logo.svg";

import QRCodeStyling from "qr-code-styling";
import SwitchPlatform from "../../SwitchPlatform";
import { CustomContext } from "../../../context/CustomContext";
import { useIframeCustomization } from "../../../context/IframeCustomizationContext";
import { RestartAlt } from "@mui/icons-material";

const qrCode = new QRCodeStyling({
  width: 350,
  height: 350,
  margin: 10,
  image: "",
  dotsOptions: {
    color: "white",
    type: "rounded",
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 10,
  },
  backgroundOptions: {
    color: "yellow",
  },
  cornersSquareOptions: {
    color: "black",
  },
});

function QrCodeOitiComponent(props) {
  const ref = useRef(null);
  useEffect(() => {
    const options = qrCode._options;
    options.cornersSquareOptions.color = props.eyeColor;
    options.image = props.centerImageSrc;
    options.backgroundOptions.color = props.bgColor;
    options.dotsOptions.color = props.fgColor;
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: props.data,
    });
  }, [props.data]);

  return (
    <div
      style={{
        backgroundColor: props.bgColor && props.bgColor,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          borderRadius: "7px",
        }}
        ref={ref}
      ></div>
    </div>
  );
}
const Timer = ({ onTimerFinish, onTimerReset }) => {
  const initialSeconds = 30;
  const [seconds, setSeconds] = useState(initialSeconds);
  const [showResetButton, setShowResetButton] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    if (seconds === 0) {
      clearInterval(intervalId);
      onTimerFinish();
      setShowResetButton(true);
    }

    return () => clearInterval(intervalId);
  }, [seconds, onTimerFinish]);

  const handleResetClick = () => {
    setSeconds(initialSeconds);
    setShowResetButton(false);
    onTimerReset();
  };

  return (
    <Grid container justifyContent="center">
      <Grid container justifyContent="center">
        <Typography>QRCode expira em: {seconds} segundos</Typography>
      </Grid>
      {showResetButton && (
        <>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            sx={{ pt: 2 }}
          >
            <Typography color="red">Seu QRCode expirou</Typography>

            <Button
              className={`btn-default`}
              variant="outlined"
              startIcon={<RestartAlt />}
              onClick={handleResetClick}
            >
              Novo QRCode
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

function GeneratedQrCode() {
  const [qrCodeData, setQrCodeData] = useState("");
  const [qrFileName, setQrFileName] = useState("");

  const {
    customBtns,
    customReadyScreen,
    customRetryScreen,
    customResultScreen,
    customOvalScreen,
    customFeedback,
    customFrame,
    customOverlay,
    os,
  } = useContext(CustomContext);

  const { handleUploadJson, uploadingJson, handleDeleteJson } =
    useIframeCustomization();

  const key =
    os === "android"
      ? "135ced42-3399-4b00-8e8e-272832672a1c"
      : "47d92eb6-ef4b-45f6-ac91-21ca51f39c06";

  const l3Theme = {
    customBtns,
    customReadyScreen,
    customRetryScreen,
    customResultScreen,
    customOvalScreen,
    customFeedback,
    customFrame,
    customOverlay,
  };

  const updateQrCode = () => {
    handleUploadJson(l3Theme)
      .then((res) => {
        const newJson = {
          key,
          customization: res?.jsonURL,
        };

        setQrFileName(res?.fileName);
        setQrCodeData(JSON.stringify(newJson));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleTimerFinish = () => {
    console.log("Timer finished!");
    console.log(qrFileName);
    handleDeleteJson(qrFileName);
  };

  const onTimerReset = () => {
    updateQrCode();
  };

  useEffect(() => {
    updateQrCode();
    if (qrFileName) {
      handleDeleteJson(qrFileName);
    }
  }, [key]);

  return (
    <div className="containerCode">
      <Grid container justifyContent="center">
        <Grid className="contentCode" item xs="10" md="10">
          <Typography
            style={{ borderLeft: "3px solid #FFFFFF", paddingLeft: "10px" }}
            variant="h5"
            fontWeight="600"
            fontFamily="Ubuntu"
            marginBottom={2}
          >
            1. APP Demo
          </Typography>
          <Typography>
            Abra nosso aplicativo de demostração(<b>APP DEMO</b>) e escaneie o
            QR Code para aplicar a customização realizada.
          </Typography>

          {/* <Grid
            item
            xs="12"
            md="12"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
            marginTop={2}
            marginBottom={2}
          >
            <Button
              className={`secondaryBtn`}
              size="large"
              variant="text"
              startIcon={<Android />}
            >
              Download Android
            </Button>
            <Button
              className={`secondaryBtn`}
              size="large"
              variant="text"
              startIcon={<Apple />}
            >
              Download iOS
            </Button>
          </Grid> */}

          <Typography
            style={{
              borderLeft: "3px solid #FFFFFF",
              paddingLeft: "10px",
              marginTop: "40px",
            }}
            variant="h5"
            fontWeight="600"
            fontFamily="Ubuntu"
            marginBottom={2}
          >
            2. Selecione a plataforma
          </Typography>
          <Typography>
            Escolha a plataforma na qual o App Demo está sendo executado.
          </Typography>
          <Grid container justifyContent="left">
            <SwitchPlatform />
          </Grid>
          <Typography
            style={{
              borderLeft: "3px solid #FFFFFF",
              paddingLeft: "10px",
              marginTop: "40px",
            }}
            variant="h5"
            fontWeight="600"
            fontFamily="Ubuntu"
            marginBottom={2}
          >
            3. Escaneie o QRCode
          </Typography>
          {uploadingJson ? (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <QrCodeOitiComponent
                data={qrCodeData}
                bgColor="#2a3240"
                fgColor="white"
                eyeColor="#00d84b"
                titleColor="white"
                centerImageSrc={logoSVG}
              />

              <Timer
                onTimerFinish={handleTimerFinish}
                onTimerReset={onTimerReset}
              />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default GeneratedQrCode;
