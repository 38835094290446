import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Button, Grid, ListItem, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { AiOutlineFontSize } from "react-icons/ai";
import { GiPencilBrush } from "react-icons/gi";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import * as React from "react";
import InputPicker from "../InputPicker";
import inputs from "./inputs";
import { LoadingButton } from "@mui/lab";
import { Android, Apple } from "@mui/icons-material";

import { CustomContext } from "../../context/CustomContext";
import InputNumber from "../InputNumber";
import FontPicker from "../FontPicker";
import EditTexts from "../EditTexts";
import { useIframeCustomization } from "../../context/IframeCustomizationContext";
import InputImage from "../InputImage";
import InputToggleButton from "../InputToggleButton";
import InputShadow from "../InputShadow";
import InputAnimation from "../InputAnimation";

import btnsJson from "./os/android/btns.json";
import guidanceJson from "./os/android/guidance.json";
import readyScreen from "./os/android/readyscreen.json";
import retryScreen from "./os/android/retryscreen.json";
import resultScreen from "./os/android/resultscreen.json";
import ovalJson from "./os/android/oval.json";
import frameJson from "./os/android/frame.json";
import overlayJson from "./os/android/overlay.json";
import feedbackJson from "./os/android/feedback.json";

import btnsJsonIOS from "./os/ios/btns.json";
import guidanceJsonIOS from "./os/ios/guidance.json";
import readyScreenIOS from "./os/ios/readyscreen.json";
import retryScreenIOS from "./os/ios/retryscreen.json";
import resultScreenIOS from "./os/ios/resultscreen.json";
import ovalJsonIOS from "./os/ios/oval.json";
import frameJsonIOS from "./os/ios/frame.json";
import overlayJsonIOS from "./os/ios/overlay.json";
import feedbackJsonIOS from "./os/ios/feedback.json";

import btnsJsonRN from "./os/reactnative/btns.json";
import guidanceJsonRN from "./os/reactnative/guidance.json";
import readyScreenRN from "./os/reactnative/readyscreen.json";
import retryScreenRN from "./os/reactnative/retryscreen.json";
import resultScreenRN from "./os/reactnative/resultscreen.json";
import ovalJsonRN from "./os/reactnative/oval.json";
import frameJsonRN from "./os/reactnative/frame.json";
import overlayJsonRN from "./os/reactnative/overlay.json";
import feedbackJsonRN from "./os/reactnative/feedback.json";

import btnsJsonFlutter from "./os/flutter/btns.json";
import guidanceJsonFlutter from "./os/flutter/guidance.json";
import readyScreenFlutter from "./os/flutter/readyscreen.json";
import retryScreenFlutter from "./os/flutter/retryscreen.json";
import resultScreenFlutter from "./os/flutter/resultscreen.json";
import ovalJsonFlutter from "./os/flutter/oval.json";
import frameJsonFlutter from "./os/flutter/frame.json";
import overlayJsonFlutter from "./os/flutter/overlay.json";
import feedbackJsonFlutter from "./os/flutter/feedback.json";

const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

export default function ConfigList({ homolog }) {
  const {
    customBtns,
    customReadyScreen,
    customRetryScreen,
    customResultScreen,
    customOvalScreen,
    customFeedback,
    customFrame,
    customOverlay,
    screen,
    os,
    handleScreen,
    customTab,
    setCustomTab,
    isIsolated,
    setIsIsolated,
    setCustomGuidance,
    setCustomBtns,
    setCustomReadyScreen,
    setCustomRetryScreen,
    setCustomResultScreen,
    setCustomOvalScreen,
    setCustomFrame,
    setCustomOverlay,
    setCustomFeedback,
  } = React.useContext(CustomContext);

  const [openBtns, setOpenBtns] = React.useState(false);
  const [openReadyScreen, setOpenReadyScreen] = React.useState(false);
  const [openRetryScreen, setOpenRetryScreen] = React.useState(false);
  const [openResultScreen, setOpenResultScreen] = React.useState(false);
  const [openOvalCustom, setOpenOvalCustom] = React.useState(false);
  const [openFrameCustom, setOpenFrameCustom] = React.useState(false);
  const [openOverlayCustom, setOpenOverlayCustom] = React.useState(false);
  const [openFeedbackCustom, setOpenFeedbackCustom] = React.useState(false);
  const [savingFirestore, setSavingFirestore] = React.useState(false);

  const [inputsData] = React.useState(inputs);
  const { updateDocument } = useIframeCustomization();
  async function handleSaveDataFirestore() {
    setSavingFirestore(true);
    const l3Theme = {
      customBtns,
      customReadyScreen,
      customRetryScreen,
      customResultScreen,
      customOvalScreen,
      customFeedback,
      customFrame,
      customOverlay,
    };
    await updateDocument(l3Theme);

    setSavingFirestore(false);
  }

  const handleChangeIsolatedMoel = () => {
    switch (os) {
      case "android":
        setCustomGuidance(guidanceJson);
        setCustomBtns(btnsJson);
        setCustomReadyScreen(readyScreen);
        setCustomRetryScreen(retryScreen);
        setCustomResultScreen(resultScreen);
        setCustomOvalScreen(ovalJson);
        setCustomFrame(frameJson);
        setCustomOverlay(overlayJson);
        setCustomFeedback(feedbackJson);
        break;
      case "ios":
        setCustomGuidance(guidanceJsonIOS);
        setCustomBtns(btnsJsonIOS);
        setCustomReadyScreen(readyScreenIOS);
        setCustomRetryScreen(retryScreenIOS);
        setCustomResultScreen(resultScreenIOS);
        setCustomOvalScreen(ovalJsonIOS);
        setCustomFrame(frameJsonIOS);
        setCustomOverlay(overlayJsonIOS);
        setCustomFeedback(feedbackJsonIOS);
        break;
      case "reactnative":
        setCustomGuidance(guidanceJsonRN);
        setCustomBtns(btnsJsonRN);
        setCustomReadyScreen(readyScreenRN);
        setCustomRetryScreen(retryScreenRN);
        setCustomResultScreen(resultScreenRN);
        setCustomOvalScreen(ovalJsonRN);
        setCustomFrame(frameJsonRN);
        setCustomOverlay(overlayJsonRN);
        setCustomFeedback(feedbackJsonRN);
        break;
      case "flutter":
        setCustomGuidance(guidanceJsonFlutter);
        setCustomBtns(btnsJsonFlutter);
        setCustomReadyScreen(readyScreenFlutter);
        setCustomRetryScreen(retryScreenFlutter);
        setCustomResultScreen(resultScreenFlutter);
        setCustomOvalScreen(ovalJsonFlutter);
        setCustomFrame(frameJsonFlutter);
        setCustomOverlay(overlayJsonFlutter);
        setCustomFeedback(feedbackJsonFlutter);
        break;
      default:
        setCustomGuidance(guidanceJson);
        setCustomBtns(btnsJson);
        setCustomReadyScreen(readyScreen);
        setCustomRetryScreen(retryScreen);
        setCustomResultScreen(resultScreen);
        setCustomOvalScreen(ovalJson);
        setCustomFrame(frameJson);
        setCustomOverlay(overlayJson);
        setCustomFeedback(feedbackJson);
        break;
    }
    if (!isIsolated) {
      setCustomGuidance(guidanceJsonIOS);
      setCustomBtns(btnsJsonIOS);
      setCustomReadyScreen(readyScreenIOS);
      setCustomRetryScreen(retryScreenIOS);
      setCustomResultScreen(resultScreenIOS);
      setCustomOvalScreen(ovalJsonIOS);
      setCustomFrame(frameJsonIOS);
      setCustomOverlay(overlayJsonIOS);
      setCustomFeedback(feedbackJsonIOS);
    } else {
      setCustomGuidance(guidanceJson);
      setCustomBtns(btnsJson);
      setCustomReadyScreen(readyScreen);
      setCustomRetryScreen(retryScreen);
      setCustomResultScreen(resultScreen);
      setCustomOvalScreen(ovalJson);
      setCustomFrame(frameJson);
      setCustomOverlay(overlayJson);
      setCustomFeedback(feedbackJson);
    }
    setIsIsolated(!isIsolated);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
          palette: {
            mode: "dark",
            primary: { main: "rgb(102, 157, 246)" },
            background: { paper: "rgb(0, 30, 52)" },
            color: { paper: "rgb(0, 30, 52)" },
          },
        })}
      >
        <Paper elevation={0} sx={{ maxWidth: "100%" }}>
          <FireNav component="nav" disablePadding>
            <Grid
              item
              xs="12"
              md="12"
              direction="row"
              style={{ display: "flex", paddingBottom: "0 !important" }}
            >
              {homolog && (
                <ListItem
                  sx={{
                    my: 0,
                    gap: 8,
                  }}
                  primary="Tema"
                  primaryTypographyProps={{
                    fontSize: 25,
                    fontWeight: "medium",
                    letterSpacing: 0,
                    fontFamily: "Ubuntu",
                  }}
                >
                  <Grid
                    container
                    spacing={4}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Grid item xs={6}>
                      <LoadingButton
                        size="large"
                        variant="outlined"
                        onClick={() => handleSaveDataFirestore()}
                        className="secondaryBtn"
                        loadingPosition="start"
                        fullWidth
                        loading={savingFirestore}
                        startIcon={<GiPencilBrush />}
                      >
                        Salvar no firebase
                      </LoadingButton>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      container
                      display="flex"
                      justifyContent="center"
                    >
                      <Tooltip title="Android">
                        <Button
                          className={`btn-default-platform ${
                            !isIsolated && "btn-default-platform-selected"
                          }`}
                          variant="outlined"
                          onClick={handleChangeIsolatedMoel}
                        >
                          <Android />
                        </Button>
                      </Tooltip>
                      <Tooltip title="iOS">
                        <Button
                          className={`btn-default-platform ${
                            isIsolated && "btn-default-platform-selected"
                          }`}
                          variant="outlined"
                          onClick={handleChangeIsolatedMoel}
                        >
                          <Apple />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </ListItem>
              )}
            </Grid>
            <Grid
              item
              xs="12"
              md="12"
              direction="row"
              style={{ display: "flex" }}
            >
              <ListItem
                sx={{ my: 0 }}
                primary="Tema"
                primaryTypographyProps={{
                  fontSize: 25,
                  fontWeight: "medium",
                  letterSpacing: 0,
                  fontFamily: "Ubuntu",
                }}
              >
                <Button
                  size="large"
                  variant="outlined"
                  onClick={() => setCustomTab("theme")}
                  className={`secondaryBtn ${
                    customTab === "theme" && "secondaryBtnActive"
                  }`}
                  fullWidth
                  startIcon={<GiPencilBrush />}
                >
                  Tema
                </Button>

                <Button
                  size="large"
                  variant="outlined"
                  onClick={() => setCustomTab("texts")}
                  className={`secondaryBtn ${
                    customTab === "texts" && "secondaryBtnActive"
                  }`}
                  fullWidth
                  startIcon={<AiOutlineFontSize />}
                >
                  Textos
                </Button>
              </ListItem>
            </Grid>

            <Divider />

            {/* <div onClick={() => handleScreen(0)}>
              <Box
                sx={{
                  bgcolor: open ? "rgba(71, 98, 130, 0.2)" : null,
                  pb: open ? 2 : 0,
                }}
              >
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => setOpen(!open)}
                  sx={{
                    px: 3,
                    pt: 2.5,
                    pb: open ? 0 : 2.5,
                    "&:hover, &:focus": { "& svg": { opacity: open ? 1 : 0 } },
                  }}
                >
                  <ListItemText
                    primary="Guidance"
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: "medium",
                      lineHeight: "20px",
                      fontFamily: "Ubuntu",
                      mb: "2px",
                    }}
                    secondary="Tela de inicialização da prova de vida 3D."
                    secondaryTypographyProps={{
                      noWrap: true,
                      fontSize: 12,
                      lineHeight: "16px",
                      color: open ? "rgba(0,0,0,0)" : "rgba(255,255,255,0.5)",
                    }}
                    sx={{ my: 0 }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      opacity: 0,
                      transform: open ? "rotate(-180deg)" : "rotate(0)",
                      transition: "0.2s",
                    }}
                  />
                </ListItemButton>
                {open &&
                  customGuidance.map((item, i) => (
                    <ListItemButton
                      key={i}
                      sx={{
                        py: 0,
                        minHeight: 32,
                        color: "rgba(255,255,255,.8)",
                      }}
                    >
                      <Grid
                        container
                        xs="12"
                        md="12"
                        paddingTop={2}
                        paddingBottom={2}
                      >
                        <Grid item xs="12" md="7" className="propsText">
                          {item.id}
                        </Grid>
                        <Grid item xs="12" md="5">
                          {item.type === "color" && (
                            <InputPicker
                              section="guidance"
                              index={i}
                              id={item.id}
                              sectiondata={inputsData.btns}
                              value={item.value}
                            />
                          )}
                          {item.type === "font" && (
                            <FontPicker
                              section="guidance"
                              index={i}
                              id={item.id}
                              sectiondata={inputsData.btns}
                              value={item.value}
                            />
                          )}
                          {item.type === "number" && (
                            <InputNumber
                              section="guidance"
                              index={i}
                              id={item.id}
                              value={item.value}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </ListItemButton>
                  ))}
              </Box>
            </div> */}

            {customTab === "theme" && (
              <>
                <Divider />
                <div onClick={() => screen === 2 && handleScreen(0)}>
                  <Box
                    sx={{
                      bgcolor: openBtns ? "rgba(71, 98, 130, 0.2)" : null,
                      pb: openBtns ? 2 : 0,
                    }}
                  >
                    <ListItemButton
                      alignItems="flex-start"
                      onClick={() => setOpenBtns(!openBtns)}
                      sx={{
                        px: 3,
                        pt: 2.5,
                        pb: openBtns ? 0 : 2.5,
                        "&:hover, &:focus": {
                          "& svg": { opacity: openBtns ? 1 : 0 },
                        },
                      }}
                    >
                      <ListItemText
                        primary="Botões"
                        primaryTypographyProps={{
                          fontSize: 15,
                          fontWeight: "medium",
                          fontFamily: "Ubuntu",
                          lineHeight: "20px",
                          mb: "2px",
                        }}
                        secondary="Botões de ação da jornada."
                        secondaryTypographyProps={{
                          noWrap: true,
                          fontSize: 12,
                          lineHeight: "16px",
                          color: openBtns
                            ? "rgba(0,0,0,0)"
                            : "rgba(255,255,255,0.5)",
                        }}
                        sx={{ my: 0 }}
                      />
                      <KeyboardArrowDown
                        sx={{
                          mr: -1,
                          opacity: 0,
                          transform: openBtns ? "rotate(-180deg)" : "rotate(0)",
                          transition: "0.2s",
                        }}
                      />
                    </ListItemButton>
                    {openBtns &&
                      customBtns.map((item, i) => (
                        <ListItemButton
                          key={i}
                          sx={{
                            py: 0,
                            minHeight: 32,
                            color: "rgba(255,255,255,.8)",
                          }}
                        >
                          <Grid
                            container
                            xs="12"
                            md="12"
                            paddingTop={2}
                            paddingBottom={2}
                          >
                            <Grid item xs="7" md="7" className="propsText">
                              {item.id}
                            </Grid>
                            <Grid item xs="12" md="5">
                              {item.type === "color" && (
                                <InputPicker
                                  section="btns"
                                  index={i}
                                  id={item.id}
                                  sectiondata={inputsData.btns}
                                  value={item.value}
                                />
                              )}
                              {item.type === "font" && (
                                <FontPicker
                                  section="btns"
                                  index={i}
                                  id={item.id}
                                  sectiondata={inputsData.btns}
                                  value={item.value}
                                />
                              )}
                              {item.type === "number" && (
                                <InputNumber
                                  section="btns"
                                  index={i}
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "image" && (
                                <InputImage
                                  section="btns"
                                  index={i}
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "cancelButtonLocation" && (
                                <InputToggleButton
                                  section="btns"
                                  index={i}
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      ))}
                  </Box>
                </div>

                <Divider />
                <div onClick={() => handleScreen(0)}>
                  <Box
                    sx={{
                      bgcolor: openReadyScreen
                        ? "rgba(71, 98, 130, 0.2)"
                        : null,
                      pb: openReadyScreen ? 2 : 0,
                    }}
                  >
                    <ListItemButton
                      alignItems="flex-start"
                      onClick={() => setOpenReadyScreen(!openReadyScreen)}
                      sx={{
                        px: 3,
                        pt: 2.5,
                        pb: openReadyScreen ? 0 : 2.5,
                        "&:hover, &:focus": {
                          "& svg": { opacity: openReadyScreen ? 1 : 0 },
                        },
                      }}
                    >
                      <ListItemText
                        primary="Ready Screen"
                        primaryTypographyProps={{
                          fontSize: 15,
                          fontWeight: "medium",
                          lineHeight: "20px",
                          fontFamily: "Ubuntu",
                          mb: "2px",
                        }}
                        secondary="Tela inicial da jordada do Liveness3D."
                        secondaryTypographyProps={{
                          noWrap: true,
                          fontSize: 12,
                          lineHeight: "16px",
                          color: openReadyScreen
                            ? "rgba(0,0,0,0)"
                            : "rgba(255,255,255,0.5)",
                        }}
                        sx={{ my: 0 }}
                      />
                      <KeyboardArrowDown
                        sx={{
                          mr: -1,
                          opacity: 0,
                          transform: openReadyScreen
                            ? "rotate(-180deg)"
                            : "rotate(0)",
                          transition: "0.2s",
                        }}
                      />
                    </ListItemButton>
                    {openReadyScreen &&
                      customReadyScreen.map((item, i) => (
                        <ListItemButton
                          key={i}
                          sx={{
                            py: 0,
                            minHeight: 32,
                            color: "rgba(255,255,255,.8)",
                          }}
                        >
                          <Grid
                            container
                            xs="12"
                            md="12"
                            paddingTop={2}
                            paddingBottom={2}
                          >
                            <Grid item xs="12" md="6" className="propsText">
                              {item.id}
                            </Grid>
                            <Grid item xs="12" md="6">
                              {item.type === "color" && (
                                <InputPicker
                                  section="ready"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "font" && (
                                <FontPicker
                                  section="ready"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "number" && (
                                <InputNumber
                                  section="ready"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      ))}
                  </Box>
                </div>
                <Divider />
                <div onClick={() => handleScreen(1)}>
                  <Box
                    sx={{
                      bgcolor: openRetryScreen
                        ? "rgba(71, 98, 130, 0.2)"
                        : null,
                      pb: openRetryScreen ? 2 : 0,
                    }}
                  >
                    <ListItemButton
                      alignItems="flex-start"
                      onClick={() => setOpenRetryScreen(!openRetryScreen)}
                      sx={{
                        px: 3,
                        pt: 2.5,
                        pb: openRetryScreen ? 0 : 2.5,
                        "&:hover, &:focus": {
                          "& svg": { opacity: openRetryScreen ? 1 : 0 },
                        },
                      }}
                    >
                      <ListItemText
                        primary="Retry Screen"
                        primaryTypographyProps={{
                          fontSize: 15,
                          fontWeight: "medium",
                          lineHeight: "20px",
                          fontFamily: "Ubuntu",
                          mb: "2px",
                        }}
                        secondary="Tela de Retentativa"
                        secondaryTypographyProps={{
                          noWrap: true,
                          fontSize: 12,
                          lineHeight: "16px",
                          color: openRetryScreen
                            ? "rgba(0,0,0,0)"
                            : "rgba(255,255,255,0.5)",
                        }}
                        sx={{ my: 0 }}
                      />
                      <KeyboardArrowDown
                        sx={{
                          mr: -1,
                          opacity: 0,
                          transform: openRetryScreen
                            ? "rotate(-180deg)"
                            : "rotate(0)",
                          transition: "0.2s",
                        }}
                      />
                    </ListItemButton>
                    {openRetryScreen &&
                      customRetryScreen.map((item, i) => (
                        <ListItemButton
                          key={i}
                          sx={{
                            py: 0,
                            minHeight: 32,
                            color: "rgba(255,255,255,.8)",
                          }}
                        >
                          <Grid
                            container
                            xs="12"
                            md="12"
                            paddingTop={2}
                            paddingBottom={2}
                          >
                            <Grid item xs="12" md="6" className="propsText">
                              {item.id}
                            </Grid>
                            <Grid item xs="12" md="6">
                              {item.type === "color" && (
                                <InputPicker
                                  section="retry"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "font" && (
                                <FontPicker
                                  section="retry"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "number" && (
                                <InputNumber
                                  section="retry"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      ))}
                  </Box>
                </div>
                <Divider />
                <div onClick={() => handleScreen(2)}>
                  <Box
                    sx={{
                      bgcolor: openResultScreen
                        ? "rgba(71, 98, 130, 0.2)"
                        : null,
                      pb: openResultScreen ? 2 : 0,
                    }}
                  >
                    <ListItemButton
                      alignItems="flex-start"
                      onClick={() => setOpenResultScreen(!openResultScreen)}
                      sx={{
                        px: 3,
                        pt: 2.5,
                        pb: openResultScreen ? 0 : 2.5,
                        "&:hover, &:focus": {
                          "& svg": { opacity: openResultScreen ? 1 : 0 },
                        },
                      }}
                    >
                      <ListItemText
                        primary="Result Screen Customization"
                        primaryTypographyProps={{
                          fontSize: 15,
                          fontWeight: "medium",
                          lineHeight: "20px",
                          fontFamily: "Ubuntu",
                          mb: "2px",
                        }}
                        secondary="Tela de resultado da jornada."
                        secondaryTypographyProps={{
                          noWrap: true,
                          fontSize: 12,
                          lineHeight: "16px",
                          color: openResultScreen
                            ? "rgba(0,0,0,0)"
                            : "rgba(255,255,255,0.5)",
                        }}
                        sx={{ my: 0 }}
                      />

                      <KeyboardArrowDown
                        sx={{
                          mr: -1,
                          opacity: 0,
                          transform: openResultScreen
                            ? "rotate(-180deg)"
                            : "rotate(0)",
                          transition: "0.2s",
                        }}
                      />
                    </ListItemButton>
                    {openResultScreen &&
                      customResultScreen.map((item, i) => (
                        <ListItemButton
                          key={i}
                          sx={{
                            py: 0,
                            minHeight: 32,
                            color: "rgba(255,255,255,.8)",
                          }}
                        >
                          <Grid
                            container
                            xs="12"
                            md="12"
                            paddingTop={2}
                            paddingBottom={2}
                          >
                            <Grid item xs="12" md="6" className="propsText">
                              {item.id}
                            </Grid>
                            <Grid item xs="12" md="6">
                              {item.type === "color" && (
                                <InputPicker
                                  section="result"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "font" && (
                                <FontPicker
                                  section="result"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "number" && (
                                <InputNumber
                                  section="result"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type ===
                                "resultScreenCustomizationAnimation" && (
                                <InputAnimation
                                  section="result"
                                  index={i}
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      ))}
                  </Box>
                </div>
                <Divider />
                <div onClick={() => handleScreen(0)}>
                  <Box
                    sx={{
                      bgColor: openOvalCustom ? "rgba(71, 98, 130, 0.2)" : null,
                      pb: openOvalCustom ? 2 : 0,
                    }}
                  >
                    <ListItemButton
                      alignItems="flex-start"
                      onClick={() => setOpenOvalCustom(!openOvalCustom)}
                      sx={{
                        px: 3,
                        pt: 2.5,
                        pb: openOvalCustom ? 0 : 2.5,
                        "&:hover, &:focus": {
                          "& svg": { opacity: openOvalCustom ? 1 : 0 },
                        },
                      }}
                    >
                      <ListItemText
                        primary="Oval Customization"
                        primaryTypographyProps={{
                          fontSize: 15,
                          fontFamily: "Ubuntu",
                          fontWeight: "medium",
                          lineHeight: "20px",
                          mb: "2px",
                        }}
                        secondary="Área de captura da face."
                        secondaryTypographyProps={{
                          noWrap: true,
                          fontSize: 12,
                          lineHeight: "16px",
                          color: openOvalCustom
                            ? "rgba(0,0,0,0)"
                            : "rgba(255,255,255,0.5)",
                        }}
                        sx={{ my: 0 }}
                      />
                      <KeyboardArrowDown
                        sx={{
                          mr: -1,
                          opacity: 0,
                          transform: openOvalCustom
                            ? "rotate(-180deg)"
                            : "rotate(0)",
                          transition: "0.2s",
                        }}
                      />
                    </ListItemButton>
                    {openOvalCustom &&
                      customOvalScreen.map((item, i) => (
                        <ListItemButton
                          key={i}
                          sx={{
                            py: 0,
                            minHeight: 32,
                            color: "rgba(255,255,255,.8)",
                          }}
                        >
                          <Grid
                            container
                            xs="12"
                            md="12"
                            paddingTop={2}
                            paddingBottom={2}
                          >
                            <Grid item xs="12" md="6" className="propsText">
                              {item.id}
                            </Grid>
                            <Grid item xs="12" md="6">
                              {item.type === "color" && (
                                <InputPicker
                                  section="oval"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "font" && (
                                <FontPicker
                                  section="oval"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "number" && (
                                <InputNumber
                                  section="oval"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      ))}
                  </Box>
                </div>
                <Divider />

                <div onClick={() => handleScreen(0)}>
                  <Box
                    sx={{
                      bgcolor: openFrameCustom
                        ? "rgba(71, 98, 130, 0.2)"
                        : null,
                      pb: openFrameCustom ? 2 : 0,
                    }}
                  >
                    <ListItemButton
                      alignItems="flex-start"
                      onClick={() => setOpenFrameCustom(!openFrameCustom)}
                      sx={{
                        px: 3,
                        pt: 2.5,
                        pb: openFrameCustom ? 0 : 2.5,
                        "&:hover, &:focus": {
                          "& svg": { opacity: openFrameCustom ? 1 : 0 },
                        },
                      }}
                    >
                      <ListItemText
                        primary="Frame Customization"
                        primaryTypographyProps={{
                          fontSize: 15,
                          fontFamily: "Ubuntu",
                          fontWeight: "medium",
                          lineHeight: "20px",
                          mb: "2px",
                        }}
                        secondary="Container que abrange todo o desafio."
                        secondaryTypographyProps={{
                          noWrap: true,
                          fontSize: 12,
                          lineHeight: "16px",
                          color: openFrameCustom
                            ? "rgba(0,0,0,0)"
                            : "rgba(255,255,255,0.5)",
                        }}
                        sx={{ my: 0 }}
                      />
                      <KeyboardArrowDown
                        sx={{
                          mr: -1,
                          opacity: 0,
                          transform: openFrameCustom
                            ? "rotate(-180deg)"
                            : "rotate(0)",
                          transition: "0.2s",
                        }}
                      />
                    </ListItemButton>
                    {openFrameCustom &&
                      customFrame.map((item, i) => (
                        <ListItemButton
                          key={i}
                          sx={{
                            py: 0,
                            minHeight: 32,
                            color: "rgba(255,255,255,.8)",
                          }}
                        >
                          <Grid
                            container
                            xs="12"
                            md="12"
                            paddingTop={2}
                            paddingBottom={2}
                          >
                            <Grid item xs="12" md="6" className="propsText">
                              {item.id}
                            </Grid>
                            <Grid item xs="12" md="6">
                              {item.type === "color" && (
                                <InputPicker
                                  section="frame"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "font" && (
                                <FontPicker
                                  section="frame"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "number" && (
                                <InputNumber
                                  section="frame"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "livenessShadow" && (
                                <InputShadow
                                  section="frame"
                                  index={i}
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      ))}
                  </Box>
                </div>
                <Divider />
                <Box
                  sx={{
                    bgcolor: openOverlayCustom
                      ? "rgba(71, 98, 130, 0.2)"
                      : null,
                    pb: openOverlayCustom ? 2 : 0,
                  }}
                >
                  <ListItemButton
                    alignItems="flex-start"
                    onClick={() => setOpenOverlayCustom(!openOverlayCustom)}
                    sx={{
                      px: 3,
                      pt: 2.5,
                      pb: openOverlayCustom ? 0 : 2.5,
                      "&:hover, &:focus": {
                        "& svg": { opacity: openOverlayCustom ? 1 : 0 },
                      },
                    }}
                  >
                    <ListItemText
                      primary="Overlay Customization"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "medium",
                        fontFamily: "Ubuntu",
                        lineHeight: "20px",
                        mb: "2px",
                      }}
                      secondary="Responsável pelo fundo do desafio."
                      secondaryTypographyProps={{
                        noWrap: true,
                        fontSize: 12,
                        lineHeight: "16px",
                        color: openOverlayCustom
                          ? "rgba(0,0,0,0)"
                          : "rgba(255,255,255,0.5)",
                      }}
                      sx={{ my: 0 }}
                    />
                    <KeyboardArrowDown
                      sx={{
                        mr: -1,
                        opacity: 0,
                        transform: openOverlayCustom
                          ? "rotate(-180deg)"
                          : "rotate(0)",
                        transition: "0.2s",
                      }}
                    />
                  </ListItemButton>
                  {openOverlayCustom &&
                    customOverlay.map((item, i) => (
                      <ListItemButton
                        key={i}
                        sx={{
                          py: 0,
                          minHeight: 32,
                          color: "rgba(255,255,255,.8)",
                        }}
                      >
                        <Grid
                          container
                          xs="12"
                          md="12"
                          paddingTop={2}
                          paddingBottom={2}
                        >
                          <Grid item xs="12" md="6" className="propsText">
                            {item.id}
                          </Grid>
                          <Grid item xs="12" md="6">
                            {item.type === "color" && (
                              <InputPicker
                                section="overlay"
                                id={item.id}
                                value={item.value}
                              />
                            )}
                            {item.type === "font" && (
                              <FontPicker
                                section="overlay"
                                id={item.id}
                                value={item.value}
                              />
                            )}
                            {item.type === "number" && (
                              <InputNumber
                                section="overlay"
                                id={item.id}
                                value={item.value}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </ListItemButton>
                    ))}
                </Box>

                <Divider />

                <div onClick={() => handleScreen(0)}>
                  <Box
                    sx={{
                      bgcolor: openFeedbackCustom
                        ? "rgba(71, 98, 130, 0.2)"
                        : null,
                      pb: openFeedbackCustom ? 2 : 0,
                    }}
                  >
                    <ListItemButton
                      alignItems="flex-start"
                      onClick={() => setOpenFeedbackCustom(!openFeedbackCustom)}
                      sx={{
                        px: 3,
                        pt: 2.5,
                        pb: openFeedbackCustom ? 0 : 2.5,
                        "&:hover, &:focus": {
                          "& svg": { opacity: openFeedbackCustom ? 1 : 0 },
                        },
                      }}
                    >
                      <ListItemText
                        primary="Feedback Customization"
                        primaryTypographyProps={{
                          fontSize: 15,
                          fontWeight: "medium",
                          fontFamily: "Ubuntu",
                          lineHeight: "20px",
                          mb: "2px",
                        }}
                        secondary="Área de avisos em tempo real."
                        secondaryTypographyProps={{
                          noWrap: true,
                          fontSize: 12,
                          lineHeight: "16px",
                          color: openFeedbackCustom
                            ? "rgba(0,0,0,0)"
                            : "rgba(255,255,255,0.5)",
                        }}
                        sx={{ my: 0 }}
                      />
                      <KeyboardArrowDown
                        sx={{
                          mr: -1,
                          opacity: 0,
                          transform: openFeedbackCustom
                            ? "rotate(-180deg)"
                            : "rotate(0)",
                          transition: "0.2s",
                        }}
                      />
                    </ListItemButton>
                    {openFeedbackCustom &&
                      customFeedback.map((item, i) => (
                        <ListItemButton
                          key={i}
                          sx={{
                            py: 0,
                            minHeight: 32,
                            color: "rgba(255,255,255,.8)",
                          }}
                        >
                          <Grid
                            container
                            xs="12"
                            md="12"
                            paddingTop={2}
                            paddingBottom={2}
                          >
                            <Grid item xs="12" md="6" className="propsText">
                              {item.id}
                            </Grid>
                            <Grid item xs="12" md="6">
                              {item.type === "color" && (
                                <InputPicker
                                  section="feedback"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "font" && (
                                <FontPicker
                                  section="feedback"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "number" && (
                                <InputNumber
                                  section="feedback"
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                              {item.type === "livenessShadow" && (
                                <InputShadow
                                  section="feedback"
                                  index={i}
                                  id={item.id}
                                  value={item.value}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      ))}
                  </Box>
                </div>
              </>
            )}
            {customTab === "texts" && <EditTexts />}
          </FireNav>
        </Paper>
      </ThemeProvider>
    </Box>
  );
}
